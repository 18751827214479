import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast'
import { Container } from './Container';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { useWindowSize } from '@hooks/useWindowSize';

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const user = useSelector((state) => state.auth?.user);
  const {width} = useWindowSize();

  return (
    <>
      <Container>
        {user && <Sidebar />}
      </Container>
      <Header />
      {/* <Page> */}
      {children}
      {/* </Page> */}
    </>
  );
};
