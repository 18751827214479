import { TournamentsStatusType } from "@newComponents/screens/Tournaments/ui/Tournaments";

interface TournamentsTypeItem {
  name: string;
  status: TournamentsStatusType;
}

export const tournamentsTypesList: TournamentsTypeItem[] = [
  {
    name: 'All',
    status: 'all'
  },
  {
    name: 'Active',
    status: 'ongoing'
  },
  {
    name: 'Ended',
    status: 'past'
  }
]