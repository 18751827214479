import { memo, useCallback } from "react";
import {
  useCancelSellMutation,
  useFetchSellingCardsQuery
} from "@services/cards.service";
import { useSelector } from "react-redux";
import { BACKEND_BASE_URL } from "@constants/constants";
import { Button } from "@newComponents/shared/Button/Button";
import toast from "react-hot-toast";
import classNames from 'classnames';
import cls from './MyClubSales.module.scss';

interface MyClubSalesProps {
  className?: string;
}

export const MyClubSales = memo((props: MyClubSalesProps) => {
  const { className } = props;
  const currentUserId = useSelector((state) => state.auth?.user?.id)
  const { data } = useFetchSellingCardsQuery(currentUserId);
  const [cancelSell] = useCancelSellMutation();

  const handleCancelSell = useCallback(async (cardId: number) => {
    try {
      await cancelSell(cardId).unwrap();
      toast.success('Card sale cancelled');
    } catch (error) {
      toast.error('An error occurred while canceling the card sale')
    }
  }, [cancelSell])

  if (data) {
    return (
      <ul className={classNames(cls.MyClubSales, className)}>
        {data.results.map((card) => (
          <li className={cls.item} key={card.id}>
            <img
              className={cls.image}
              src={BACKEND_BASE_URL + card.image_card}
            />
            <span className={cls.price}>
              $ {card.price}
            </span>
            <Button
              className={cls.button}
              theme="contained-grey"
              onClick={() => handleCancelSell(card.product_id)}
            >
              Cancel sale
            </Button>
          </li>
        ))}
      </ul>
    )
  }
})