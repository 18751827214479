import {
  Route, Routes,
} from 'react-router-dom';
import ProfileLayout from '@screens/Profile/ProfileLayout';
import NotFoundPage from '@screens/NotFoundPage';
import Login from '@screens/Authentication/Login';
import ProtectedRoute from '@routes/ProtectedRoute';
import ProtectedRouteElementForAuthorized from '@routes/ProtectedRouteForAuthorized';
import ProtectedRouteForStudy from '@routes/ProtectedRouteForStudy';
import MyTeams from '@screens/Profile/MyTeams';
import RegistrationFirstStep from '@screens/Authentication/RegistrationFirstStep';
import CodeConfirmation from '@screens/Authentication/CodeConfirtmation';
import RegistrationTwoStep from '@screens/Authentication/RegistrationTwoStep';
import Composition from '@screens/Composition/Composition';
import MyAchievments from '@screens/Profile/MyAchievments';
import MyCards from '@screens/Profile/MyCards';
import Grid_Tournament from '@screens/Tournament/Grid_Tournament';
import Main_Tournament from '@screens/Tournament/Main_Tournament';
import Card_Prize from '@screens/Tournament/Card_Prize';
import {
  ROUTE_ACHIEVEMENTS,
  ROUTE_CARD,
  ROUTE_CARDS,
  ROUTE_CARDS_DROP,
  ROUTE_CASE_PAGE,
  ROUTE_CODE_CONFIRMATION,
  ROUTE_CONTACTS_PAGE,
  ROUTE_CONTROL_COMMAND,
  ROUTE_GAME_RULES,
  ROUTE_GET_FIRST_CARDS,
  ROUTE_INVITE_PAGE,
  ROUTE_LOGIN,
  ROUTE_MAIN,
  ROUTE_MARKETPLACE,
  ROUTE_MY_TEAMS,
  ROUTE_MYCOMPOSITION,
  ROUTE_MYSTERY_CASE_PAGE,
  ROUTE_PARTNER,
  ROUTE_PARTNER_INFO,
  ROUTE_POLICY_PAGE,
  ROUTE_PROFILE,
  ROUTE_RATING,
  ROUTE_REGISTRATION_TWO,
  ROUTE_RESET_PASSWORD,
  ROUTE_SENDCODE,
  ROUTE_TERMS_PAGE,
  ROUTE_TOURNAMENT,
  ROUTE_TOURNAMENT_CALENDAR,
  ROUTE_TRAINING,
} from '@constants/constants';
import PartnerForm from '@screens/Partner/PartnerForm';
import ResetPassword from '@screens/Authentication/ResetPassword';
import Training from '@screens/Training';
import MarketplaceMain from '@screens/Marketplace/MarketplaceMain';
import GetRegistrationCard from '@screens/Authentication/GetRegistrationCard';
import SellCard from '@screens/Marketplace/SellCard';
import GoSellPage from '@screens/Marketplace/Pages/GoSellPage';
import CalendarTournament from '@screens/Tournament/CalendarTournament';
import PartnerInfo from '@screens/Partner/PartnerInfo';
import MyCard from '@screens/Profile/MyCard';
import Upgrade from '@screens/Upgrade';
import ControlCommand from '@screens/ControlCommand';
import LearnReplaceCardPage from '@screens/Learning/LearnReplaceCardPage';
import '../vendor';
import GameRulesPage from '@screens/GameRulesPage';
import TermsPage from '@screens/TermsPage';
import Policy from '@screens/Policy';
import ContactsPage from '@screens/ContactsPage';
import InvitePage from '@screens/Referal';
import CardsDropPage from '@screens/CardsDropPage';
import 'react-toastify/dist/ReactToastify.css';
import CasePage from '@screens/CasePage';
import FlappyBirdGame from '@screens/FlappyBirdGame/ui';
import { PingPongGame } from '@screens/PingPongGame';
import MyPlayers from '@screens/Profile/MyPlayers';
import MysteryCasePage from '@screens/MysteryCasePage';
import { GameOverlay } from '@components/GameOverlay/GameOverlay';
import TetrisGame from '@components/TetrisGame';
import { CardPage } from '@screens/CardPage';
import { FC, useState } from 'react';
// import { Tournament } from '@models/Tournament';
import RatingPage from '@screens/Rating/Rating';
import { News } from '@newComponents/screens/News';
import { TournamentsInfo } from '@newComponents/screens/TournamentsInfo';
import { TournamentsCalendar } from '@newComponents/screens/TournamentsCalendar';
import { Tournament } from '@newComponents/screens/Tournament';
import { TournamentGrid } from '@newComponents/screens/TournamentGrid';
import { MysteryCases } from '../newComponents/screens/MysteryCases';
import { Main } from '../newComponents/screens/Main';
import { Rating } from '../newComponents/screens/Rating';
import { MarketCards } from '../newComponents/screens/MarketCards';
import { PlayerCard } from '../newComponents/ui/PlayerCard';
import { ImageService } from '../newComponents/screens/ImageService/ImageService';
import { Profile } from '../newComponents/screens/Profile';
import { MiniGames } from '../newComponents/screens/MiniGames';
import { Referal } from '../newComponents/screens/Referal';
import { CardInfo } from '../newComponents/screens/CardInfo';
import { Tournaments } from '../newComponents/screens/Tournaments';
import { Market } from '../newComponents/screens/Market';
import { MyClub } from '../newComponents/screens/MyClub';
import { MysteryCase } from '../newComponents/screens/MysteryCase';
import { TournamentChangeTeam } from '@newComponents/screens/TournamentChangeTeam';
import { UpgradePlayerCard } from '@newComponents/screens/UpgradePlayerCard';
import { FriendlyTournament } from '@newComponents/screens/FriendlyTournament';
import { ShowCard } from '@newComponents/screens/ShowCard';
import { Instruction } from '@newComponents/screens/Instruction';
import { SearchFriends } from '@newComponents/screens/SearchFriends';
import { MysteryPack } from '@newComponents/screens/MysteryPack';

interface AppRouterProps {
  isRegisterTournament: boolean;
  setIsRegisterTournament: (val: boolean) => void;
}

export const AppRouter: FC<AppRouterProps> = (props) => {
  const { isRegisterTournament, setIsRegisterTournament } = props;
  const [idTournament, setIdTournament] = useState<number | null>(null);
  const [tournamentCard, setTournamentCard] = useState<any>();
  const [friendlyInviteUserId, setFriendlyInviteUserId] = useState<number | null>(null);
  return (
    <Routes>
      <Route path={ROUTE_MAIN} element={<Main />} />
      <Route path={ROUTE_GAME_RULES} element={<GameRulesPage />} />
      <Route path={ROUTE_TERMS_PAGE} element={<TermsPage />} />
      <Route path={ROUTE_POLICY_PAGE} element={<Policy />} />
      <Route path={ROUTE_INVITE_PAGE} element={<InvitePage />} />
      <Route path={ROUTE_CONTACTS_PAGE} element={<ContactsPage />} />
      <Route path={`${ROUTE_RATING}/*`} element={<Rating />} />
      <Route path="/rating2" element={<RatingPage />} />
      <Route path={ROUTE_PARTNER} element={<PartnerForm />} />
      <Route path="news" element={<News />} />
      <Route
        path={ROUTE_PARTNER_INFO}
        element={<PartnerInfo />}
      />
      <Route path="/mini-games/*" element={<MiniGames />} />
      <Route path={ROUTE_CARDS_DROP} element={<CardsDropPage />} />
      {/* <Route path={ROUTE_MAIN} element={<MainPage />} /> */}
      <Route element={<ProtectedRoute />}>
        <Route path="/my-club/upgrade/:playerId" element={<UpgradePlayerCard />} />
        {/* <Route path="/tournaments" element={<Tournaments />} /> */}
        <Route path="cards" element={<ImageService />} />
        <Route path="/tournaments" element={<TournamentsInfo />} />
        <Route path="/tournaments/:tournamentId" element={<Tournament />} />
        <Route path="/tournaments/pro" element={<Tournaments />} />
        <Route path="/tournaments/calendar" element={<TournamentsCalendar />} />
        <Route path='/tournaments/friendly-match/' element={<FriendlyTournament />} />
        <Route path="/tournaments/:tournamentId/grid/" element={<TournamentGrid />} />
        <Route path="/tournaments/:tournamentId/change-team/" element={<TournamentChangeTeam />} />
        <Route
          path={ROUTE_CASE_PAGE}
          element={<CasePage />}
        />
        <Route
          path="/referal"
          element={<Referal />}
        />
        <Route
          path={ROUTE_MYSTERY_CASE_PAGE}
          element={<MysteryCasePage />}
        />
        <Route
          path={ROUTE_CARD}
          element={typeof window === 'undefined' ? null : <CardPage />}
        />
        <Route
          path={`${ROUTE_TRAINING}/*`}
          element={<Training />}
        />
        <Route
          path={`${ROUTE_CARDS}/:card_id`}
          element={<MyCard />}
        />
        <Route
          path={ROUTE_MY_TEAMS}
          element={(
            <MyTeams
              idTournament={idTournament}
              isRegisterTournament={isRegisterTournament}
              setIsRegisterTournament={setIsRegisterTournament}
              setIdTournament={setIdTournament}
              tournamentCard={tournamentCard}
              friendlyInviteUserId={friendlyInviteUserId}
            />
          )}
        />
        <Route
          path={ROUTE_ACHIEVEMENTS}
          element={<MyAchievments />}
        />
        <Route
          path="/players"
          element={<MyPlayers />}
        />
        <Route path={ROUTE_CARDS} element={<MyCards />} />
        <Route
          path={`${ROUTE_MYCOMPOSITION}/:team_id/*`}
          element={<Composition />}
        />
        <Route
          path={`${ROUTE_PROFILE}/:profileId` + '*'}
          element={(
            <Profile />
          )}
        />
        <Route
          path={ROUTE_GET_FIRST_CARDS}
          element={<GetRegistrationCard />}
        />
        {/* <Route
        path={`${ROUTE_MARKETPLACE}new_sell/`}
        element={<GoSellPage />}
      />
      <Route
        path={`${ROUTE_MARKETPLACE}new_sell/:card_id/*`}
        element={<SellCard />}
      />
      <Route
        path={`${ROUTE_MARKETPLACE}*`}
        element={<MarketplaceMain />}
      /> */}

        <Route path="/test-player" element={<PlayerCard />} />
        <Route path="/image-service" element={<ImageService />} />
        {/* <Route path='/market/cards' element={<MarketCards />} /> */}

        <Route path="/market/*" element={<Market />} />
        <Route path="/my-club/*" element={<MyClub />} />

        <Route path="/market/cards/:cardId" element={<CardInfo />} />
        <Route
          path="/market/mystery/:mysteryId"
          element={<MysteryCase />}
        />
        <Route
          path="/market/packs/:packId"
          element={<MysteryPack />}
        />
        <Route
          path='show'
          element={<ShowCard />}
        />

        <Route
          path='instruction/*'
          element={<Instruction />}
        />
        <Route
          path='search'
          element={<SearchFriends />}
        />
        {/* <Route path='/market/*' element={<MarketplaceMain />} /> */}
        {/* <Route path='/market/mystery' element={<MysteryCases />} /> */}
        {/* <Route
        path={`${ROUTE_TOURNAMENT_CALENDAR}`}
        element={<CalendarTournament />}
      />
      <Route
        path={`${ROUTE_TOURNAMENT}/:tournament_id/*`}
        element={(
          <Grid_Tournament
            setIdTournament={setIdTournament}
            tournament={tournamentCard}
          />
        )}
      />
      <Route
        path={`${ROUTE_TOURNAMENT}/:tournament_id/:round_id/*`}
        element={
          <Card_Prize setIdTournament={setIdTournament} />
        }
      /> */}
        {/* <Route
        path={ROUTE_TOURNAMENT}
        element={(
          <Main_Tournament
            setIsRegisterTournament={setIsRegisterTournament}
            setTournamentCard={setTournamentCard}
            setIdTournament={setIdTournament}
          />
        )}
      /> */}
        <Route
          path="/game_flappy"
          element={(
            <GameOverlay name="Игра Tennis Bird" notRotateForMobile>
              <FlappyBirdGame />
            </GameOverlay>
          )}
        />
        <Route
          path="/tetris"
          element={(
            <GameOverlay closeWithReload name="Игра Tetris" notRotateForMobile>
              <TetrisGame />
            </GameOverlay>
          )}
        />
        <Route
          path="/tennis"
          element={(
            <GameOverlay name="Игра Ping Pong">
              <PingPongGame />
            </GameOverlay>
          )}
        />
        <Route path="upgrade/*" element={<Upgrade />} />
        <Route element={<ProtectedRouteForStudy />}>
          {/* {user?.is_learned && */}
          {/* <Route
          path={`${ROUTE_TOURNAMENT}/0`}
          element={<LearnReplaceCardPage />}
        /> */}
        </Route>
        <Route
          path={`${ROUTE_CONTROL_COMMAND}/*`}
          element={<ControlCommand />}
        />
      </Route>
      <Route element={<ProtectedRouteElementForAuthorized />}>
        <Route
          path={ROUTE_SENDCODE}
          element={<RegistrationFirstStep />}
        />
        <Route path={ROUTE_LOGIN} element={<Login />} />
        <Route
          path={ROUTE_CODE_CONFIRMATION}
          element={<CodeConfirmation />}
        />
        <Route
          path={ROUTE_REGISTRATION_TWO}
          element={<RegistrationTwoStep />}
        />
        <Route
          path={ROUTE_RESET_PASSWORD}
          element={<ResetPassword />}
        />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
