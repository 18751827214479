import { BACKEND_URL } from "@constants/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const refreshAccessToken = createAsyncThunk('auth/tokenRefreshing', async (_, thunkAPI) => {
  try {
    await axios.get(`${BACKEND_URL}/account/refresh/`);
  } catch (error) {
    thunkAPI.rejectWithValue('Cannot refresh token')
  }
})