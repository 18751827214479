import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import { TournamentCardImageBlock } from '@newComponents/ui/tournaments/TournamentCardImageBlock';
import { TournamentPrizePool } from '@newComponents/ui/tournaments/TournamentPrizePool';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AuthService from '@services/AuthService';
import axios from 'axios';
import { BACKEND_URL } from '@constants/constants';
import { useFetchTournamentDetailsQuery } from '@services/tournaments.service';
import { Modal } from '@newComponents/shared/Modal/Modal';
import { TournamentLiveStats } from '../../TournamentLiveStats';
import { TournamentLiveReplacementBlock } from '../../TournamentLiveReplacementBlock';
import { TournamentLiveTimer } from '../../TournamentLiveTimer';
import cls from './TournamentLiveInfo.module.scss';
import { TournamentResultModal } from '../../TournamentResultModal';
import { TournamentResultType } from '../../TournamentResultModal/ui/TournamentResultModal';
import { Button } from '@newComponents/shared/Button/Button';

interface TournamentLiveInfoProps {
  className?: string;
}

const mockTournament = {
  id: 1156,
  name: 'wed07 15',
  place: 'Объединенные Арабские Эмираты',
  image: null,
  participants: 16,
  category: 'NFT 1000',
  cover: 'Трава',
  status_tournament: 'НЕ НАЧАЛСЯ',
};

export interface Score {
  player1: number[];
  player2: number[];
}

export const TournamentLiveInfo = memo((props: TournamentLiveInfoProps) => {
  const { className } = props;
  const { tournamentId } = useParams<{tournamentId: string}>();
  const { data } = useFetchTournamentDetailsQuery(tournamentId, {
    pollingInterval: 3000,
  });
  const [score, setScore] = useState<Score>();
  const [typeOfModal, setTypeOfModal] = useState<'looser' | 'winner'>();
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    const socket = new WebSocket(`wss://nft-tennis.com/api/tournament/${tournamentId}/?token=${AuthService.getCurrentUser().token}`);

    socket.onopen = () => {
      console.log('Connected to WebSocket server');
    };

    socket.onmessage = (event) => {
      setScore(JSON.parse(event.data));
    };

    socket.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      socket.close();
    };
  }, [tournamentId]);

  useEffect(() => {
    const isLosser = data?.loosers.includes(currentUser.id);
    const isWinner = data?.winner?.id === currentUser.id;
    if (isLosser) {
      setTypeOfModal('looser');
    }
    if (isWinner) {
      setTypeOfModal('winner');
    }
  }, [data?.loosers, data?.winner, currentUser.id]);

  useEffect(() => {
    if (data && data.status_tournament === 'ЗАКОНЧИЛСЯ') {
      navigate(`/tournaments/${tournamentId}/grid`)
    }
  }, [data, navigate])

  const handleClose = useCallback(() => {
    setTypeOfModal(null)
  }, []);

  const dateEnd = useMemo(() => new Date(data?.current_round_end), [data?.current_round_end]);

  const dateBreakEnd = useMemo(() => {
    if (data?.is_break) {
      return new Date(dateEnd.setMinutes(dateEnd.getMinutes() + data.duration_break));
    }
    return null;
  }, [data?.is_break, dateEnd, data?.duration_break]);

  if (data) {
    return (
      <div className={classNames(cls.TournamentLiveInfo, className)}>
        <TournamentCardImageBlock
          className={cls.imageBlock}
          tournament={data}
          size="small"
        />
        {data.status_tournament !== 'ЗАКОНЧИЛСЯ' && (
          <div className={classNames(cls.blockWrapper, cls.timerBlock)}>
            <TournamentLiveTimer
              className={cls.timer}
              tournament={data}
              dateEnd={dateBreakEnd || dateEnd}
            />
          </div>
        )}
        <div className={classNames(cls.blockWrapper, cls.prizeBlock)}>
          <TournamentPrizePool />
        </div>
        <Link className={cls.gridLink} to={`/tournaments/${tournamentId}/grid`}>
          <Button theme='contained_opacity-black' className={cls.gridButton}>
            Open tournament grid
          </Button>
        </Link>
        {/* <TournamentLiveReplacementBlock /> */}
        {score && (
          <div className={cls.liveStatsBlock}>
            <TournamentLiveStats score={score} />
          </div>
        )}
        {typeOfModal && (
          <TournamentResultModal
            type={typeOfModal}
            onClose={handleClose}
          /> 
        )}
      </div>
    );
  }
});
