import { memo } from 'react';
import { BACKEND_BASE_URL } from '@constants/constants';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from '../../../../newComponents/shared/Button/Button';
import cls from './MysteryCaseCard.module.scss';

interface MysteryCaseCardProps {
  name: string;
  image: string;
  linkHref: string;
  className?: string;
}

export const MysteryCaseCard = memo((props: MysteryCaseCardProps) => {
  const { name, image, linkHref, className } = props;

  return (
    <div className={classNames(cls.MysteryCaseCard, className)}>
      <img
        className={cls.image}
        src={image}
        alt={name}
      />
      <div className={cls.textWrapper}>
        <h3 className={cls.name}>
          {name}
        </h3>
        <span className={cls.price}>
          0,25$
        </span>
      </div>
      <Link
        className={cls.linkButton}
        to={linkHref}
      >
        <Button className={cls.button} theme="contained-grey">
          Buy
        </Button>
      </Link>
    </div>
  );
});
