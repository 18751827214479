import { BACKEND_URL } from '@constants/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from './AuthService';
import { getStandartBaseQuery } from '../config/getStandartBaseQuery';

export interface ArticleType {
  created_at: string;
  id: number;
  image: string;
  text: string;
  title: string;
}

export const newsAPI = createApi({
  reducerPath: 'newsAPI',
  baseQuery: getStandartBaseQuery,
  endpoints: (builder) => ({
    fetchNews: builder.query<ArticleType[], void>({
      query: () => ({
        url: '/news/news/',
      }),
    }),
    fetchTournamentsNews: builder.query<ArticleType[], void>({
      query: () => ({
        url: '/tournaments/news/',
      }),
    }),
  }),
});

export const {
  useFetchNewsQuery,
  useFetchTournamentsNewsQuery,
} = newsAPI;
