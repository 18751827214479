import { Modal } from "@newComponents/shared/Modal/Modal";
import { memo } from "react";
import classNames from 'classnames';
import cls from './SubscribersModal.module.scss';
import { useFetchUserFriendsQuery } from "@services/users.service";
import { useParams } from "react-router-dom";
import { ProfileFriendCard } from "../../ProfileFriendCard";

interface SubscribersModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

export const SubscribersModal = memo((props: SubscribersModalProps) => {
  const { isOpen, onClose, className } = props;
  const { profileId } = useParams<{profileId: string}>();
  const { data } = useFetchUserFriendsQuery(profileId);

  return (
    <Modal
      contentClassName={classNames(cls.SubscribersModal, className)}
      isOpen={isOpen}
      onClose={onClose}
    >
      <h3 className={cls.title}>
        Подписчики
      </h3> 
      <ul className={cls.list}>
        {data && data.map((user) => (
          <li className={cls.item} key={user.id}>
            <ProfileFriendCard user={user} />
          </li>
        ))}
      </ul> 
    </Modal>
  )
})