import { memo } from "react";
import { TournamentsStatusType, TournamentsType } from "@newComponents/screens/Tournaments";
import { tournamentsTypesList } from "../model/tournaments";
import classNames from 'classnames';
import cls from './TournamentsFilters.module.scss';

interface TournamentsFiltersProps {
  tournamentsType: TournamentsType;
  onChangeTournamentsType: (type: TournamentsStatusType) => void;
  className?: string;
}

export const TournamentsFilters = memo((props:TournamentsFiltersProps) => {
  const { tournamentsType, onChangeTournamentsType, className } = props;

  return (
    <div className={classNames(cls.TournamentsFilters, className)}>
      <ul className={cls.list}>
        {tournamentsTypesList.map((type) => (
          <li
            key={type.status}
            className={classNames(cls.item, type.status === tournamentsType && cls.active)}
            onClick={() => onChangeTournamentsType(type.status)}
          >
            <h3 className={cls.name}>
              {type.name}
            </h3>
          </li>
        ))}
      </ul>
    </div>
  )
})