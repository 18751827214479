import { Page } from '@newComponents/shared/Page/Page';
import { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ArticleType, useFetchNewsQuery } from '@services/news.service';

import newsMockImage from '@assets/images/news-mock-image.jpg';
import cls from './News.module.scss';
import { getFormatDateWithoutTime } from '@utils/DateUtils';
import { BackButton } from '@newComponents/shared/BackButton/BackButton';
import { BACKEND_BASE_URL } from '@constants/constants';
import { PageLoader } from '@newComponents/ui/PageLoader';

interface NewsProps {
  className?: string;
}

function catchUpWindow() {
  console.log('render')
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}

export const News = memo((props: NewsProps) => {
  const { className } = props;
  const { data, isLoading, isError } = useFetchNewsQuery();
  const [currentArticle, setCurrentArticle] = useState<ArticleType>();

  useEffect(() => {
    catchUpWindow();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setCurrentArticle(data[0]);
    }
  }, [data, isLoading, isError])

  const handleChangeArticle = useCallback((article: ArticleType) => { 
    catchUpWindow();
    setCurrentArticle(article);
  }, [])

  if (isLoading && !currentArticle) {
    return (
      <PageLoader />
    )
  }

  if (currentArticle) {
    return (
      <Page containerClassName={classNames(cls.News, className)}>
        <div className={cls.content}>
          <BackButton className={cls.backButton} />
          <span className={cls.date}>
            {getFormatDateWithoutTime(currentArticle.created_at)}
          </span>
          <h3 className={cls.title}>
            {currentArticle.title}
          </h3>
          <p className={classNames(cls.description, cls.withMargin)}>
            {currentArticle.text}
          </p>
          <img
            className={classNames(cls.image, cls.withMargin)}
            src={BACKEND_BASE_URL + currentArticle.image}
            alt=""
          />
        </div>
        <ul className={cls.list}>
          {data && data.map((article) => (
            <li
              className={cls.item}
              key={article.id}
              onClick={() => handleChangeArticle(article)}
            >
              <h4 className={cls.itemTitle}>
                {article.title}
              </h4>
              <span className={cls.itemDate}>
                {getFormatDateWithoutTime(article.created_at)}
              </span>
            </li>
          ))}
        </ul>
      </Page>
    );
  }

  return <></>
});
