import { BACKEND_URL } from '@constants/constants';
import { getAccessToken, isTokenExpired, refreshAccessToken } from '@services/token.service';
import axios from 'axios';

const $api = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true,
});

// Перехватчик запросов для обновления токена
$api.interceptors.request.use(async (config) => {
  const token = getAccessToken();

  if (token && isTokenExpired(token)) {
    await refreshAccessToken();
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export {$api}
