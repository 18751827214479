import { BACKEND_URL } from '@constants/constants';
import AuthService from '@services/AuthService';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PlayerCard } from '../../ui/PlayerCard';
import cls from './ImageService.module.scss';

import image11 from './page1/1.png';
import image12 from './page1/2.png';
import image13 from './page1/3.png';
import image14 from './page1/4.png';
import image15 from './page1/5.png';
import image16 from './page1/6.png';
import image17 from './page1/7.png';
import image18 from './page1/8.png';
import image19 from './page1/9.png';
import image110 from './page1/10.png';
import image111 from './page1/11.png';
import image112 from './page1/12.png';
import image113 from './page1/13.png';
import image114 from './page1/14.png';
import image115 from './page1/15.png';
import image116 from './page1/16.png';
import image118 from './page1/18.png';
import image119 from './page1/19.png';
import image120 from './page1/20.png';
import image121 from './page1/21.png';
import image122 from './page1/22.png';
import image123 from './page1/23.png';
import image124 from './page1/24.png';
import image125 from './page1/25.png';
import image126 from './page1/26.png';
import image127 from './page1/27.png';
import image128 from './page1/28.png';
import image129 from './page1/29.png';
import image130 from './page1/30.png';

import image21 from './page2/1.png';
import image22 from './page2/2.png';
import image23 from './page2/3.png';
import image24 from './page2/4.png';
import image25 from './page2/5.png';
import image26 from './page2/6.png';
import image27 from './page2/7.png';
import image28 from './page2/8.png';
import image29 from './page2/9.png';
import image210 from './page2/10.png';
import image211 from './page2/11.png';
import image212 from './page2/12.png';
import image213 from './page2/13.png';
import image214 from './page2/14.png';
import image215 from './page2/15.png';
import image216 from './page2/16.png';
import image217 from './page2/17.png';
import image218 from './page2/18.png';
import image219 from './page2/19.png';
import image220 from './page2/20.png';

const page1 = [
  image11,
  image12, 
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image110,
  image111,
  image112,
  image113,
  image114,
  image115,
  image116,
  null,
  image118,
  image119,
  image120,
  image121,
  image122,
  image123,
  image124,
  image125,
  image126,
  image127,
  image128,
  image129,
  image130
];

const page2 = [
  image21,
  image22, 
  image23,
  image24,
  image25,
  image26,
  image27,
  image28,
  image29,
  image210,
  image211,
  image212,
  image213,
  image214,
  image215,
  image216,
  image217,
  image218,
  image219,
  image220,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
]

export const ImageService = () => {
  const [data, setData] = useState();
  const userToken = localStorage.getItem('token');

  useEffect(() => {
    fetchCards();
  }, []);

  async function fetchCards() {
    const { data } = await axios.get(`${BACKEND_URL}/nft/nft/all/`, {
      params: {
        page: 24,
      },
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    setData(data);
    console.log(data);
  }

  return (
    <div className="ml-[200px]">
      <h2>Service</h2>
      {data && data.results.map((item, index) => (
        <PlayerCard card={item} playerImage={page1[index]} />
      ))}
    </div>
  );
};
