import { memo, useEffect, useState } from 'react';
import LogoIcon from '@assets/icons/logo.svg';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import cls from './Sidebar.module.scss';
import { sidebarItems } from '../model/sidebar';
import { SidebarItem } from './SidebarItem/SidebarItem';
import { useWindowSize } from '@hooks/useWindowSize';

interface SidebarProps {
  className?: string;
}

export const Sidebar = memo((props: SidebarProps) => {
  const { className } = props;
  const { width } = useWindowSize();
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlSidebar = () => {
    if (typeof window !== 'undefined') {
      const currentScrollY = window.scrollY;
      
      if (Math.abs(currentScrollY - lastScrollY) > 100) { // 50px - чувствительность
        if (currentScrollY > lastScrollY) {
          setIsVisible(false); // Прокрутка вниз - скрываем сайдбар
        } else {
          setIsVisible(true); // Прокрутка вверх - показываем сайдбар
        }
        setLastScrollY(currentScrollY); // Обновляем последнее значение прокрутки
      }
    }
  };

  useEffect(() => {
    if (width < 768) {
      window.addEventListener('scroll', controlSidebar);
    }
    return () => {
      window.removeEventListener('scroll', controlSidebar);
    };
  }, [lastScrollY]);

  return (
    <aside className={classNames(cls.Sidebar, className, {
      [cls.hidden]: !isVisible, // Добавляем класс для скрытия
    })}>
      {width > 768 && (
        <Link to="/">
          <LogoIcon className={cls.logo} />
        </Link>
      )}
      <nav className={cls.nav}>
        <ul className={cls.navList}>
          {sidebarItems.map((item) => (
            <li key={`${item.name}_${item.to}`} className={cls.item}>
              <SidebarItem item={item} />
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
});
