import { memo } from "react";
import { useFetchAllPacksQuery } from "@services/mystery.service";
import { MysteryCaseCard } from "@newComponents/ui/MysteryCaseCard";
import { BACKEND_BASE_URL } from "@constants/constants";
import classNames from 'classnames';
import cls from './MysteryPacks.module.scss';

interface MysteryPacksProps {
  className?: string;
}

export const MysteryPacks = memo((props: MysteryPacksProps) => {
  const { className } = props;
  const { data } = useFetchAllPacksQuery();

  return (
    <div className={classNames(cls.MysteryPacks, className)}>
      {data && (
        <ul className={cls.list}>
          {data.map((item) => (
            <li className={cls.item}>
              <MysteryCaseCard
                name={item.pack.rarity}
                image={BACKEND_BASE_URL + item.pack.image}
                linkHref={`/market/packs/${item.pack.id}`}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
})