import { memo } from 'react';
import { useWindowSize } from '@hooks/useWindowSize';
import { MarketFilters } from '../../MarketFilters';
import { MarketCardsList } from '../../MarketCardsList';
import { useFetchAuctionCardsQuery } from '@services/cards.service';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import cls from './MarketAuction.module.scss';

interface MarketAuctionProps {
  isMyClub?: boolean;
  className?: string;
}

const MarketAuction = memo((props: MarketAuctionProps) => {
  const { isMyClub, className } = props;
  const { width } = useWindowSize();
  const currentUserId = useSelector((state) => state.auth.user.id);
  const filters = useSelector((state) => state?.market?.filters);
  const { data, isLoading, isFetching } = useFetchAuctionCardsQuery(isMyClub ? {
    ...filters,
    user_id: currentUserId
  } : filters);

  return (
    <div className={classNames(cls.MarketAuction, className)}>
      {width > 768 && <MarketFilters />}
      <MarketCardsList
        cards={data}
        isLoading={isLoading}
        isFetching={isFetching}
        isAuction
        isMyClub={isMyClub}
      />
    </div>
  );
});

export default MarketAuction;
