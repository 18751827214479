import { memo, useCallback } from 'react';
import classNames from 'classnames';
import { Profile, useSubscribeMutation, useUnsubscribeMutation } from '@services/profile.service';
import { BACKEND_BASE_URL } from '@constants/constants';
import AuthService from '@services/AuthService';
import { getFormatNumber } from '@utils/getFormatNumber';
import { Avatar } from '../../../../shared/Avatar/Avatar';
import cls from './ProfileInfoCard.module.scss';
import { useParams } from 'react-router-dom';
import { Button } from '@newComponents/shared/Button/Button';
import { useMenu } from '@hooks/useMenu';
import { Modal } from '@newComponents/shared/Modal/Modal';
import { SubscribersModal } from '../../SubscribersModal';
import { SubscriptionsModal } from '../../SubscriptionsModal';
import { useSelector } from 'react-redux';

interface ProfileInfoCardProps {
  user: Profile;
  className?: string;
}

export const ProfileInfoCard = memo((props: ProfileInfoCardProps) => {
  const { user, className } = props;
  const currentUser = useSelector((state) => state.auth.user);
  const { profileId } = useParams<{ profileId: string }>();

  const {open, handleOpen, handleClose} = useMenu()

  const [subscribe, {
    isLoading: subscribeLoading
  }] = useSubscribeMutation();

  const [unsubscribe, {
    isLoading: unsubscribeLoading
  }] = useUnsubscribeMutation();

  const handleSubscribe = useCallback(() => {
    subscribe(profileId);
  }, [subscribe, profileId])

  const handleUnsubscribe = useCallback(() => {
    unsubscribe(profileId);
  }, [unsubscribe, profileId]);

  const handleOpenSubscribersModal = useCallback(() => {
    handleOpen('subscribers')
  }, [])

  const handleOpenSubscriptionsModal = useCallback(() => {
    handleOpen('subscriptions')
  }, [])

  console.log(profileId, currentUser)

  return (
    <div className={classNames(cls.ProfileInfoCard, className)}>
      <Avatar
        className={cls.avatar}
        src={user?.avatar && BACKEND_BASE_URL + user?.avatar}
        size="big"
      />
      <h3 className={cls.name}>{user?.nickname}</h3>
      <ul className={cls.followers}>
        <li
          className={cls.followersBlock}
          role='button'
          onClick={handleOpenSubscribersModal}
        >
          <h3 className={cls.followersCount}>
            {user?.followers_count}
          </h3>
          <span
            className={cls.followersBlockName}
          >
            subscribers
          </span>
        </li>
        <li
          className={cls.followersBlock}
          role='button'
          onClick={handleOpenSubscriptionsModal}
        >
          <h3 className={cls.followersCount}>
            {user?.followings_count}
          </h3>
          <span
            className={cls.followersBlockName}
          >
            subscriptions
          </span>
        </li>
      </ul>
      <ul className={cls.skills}>
        <li className={cls.skillBlock}>
          <span className={cls.skillBlockName}>
            Level
          </span>
          <h3 className={cls.skillCount}>
            5
          </h3>
        </li>
        <li className={cls.skillBlock}>
          <span className={cls.skillBlockName}>
            Experience
          </span>
          <h3 className={cls.skillCount}>
            5
          </h3>
        </li>
      </ul>
      {currentUser.id === user?.id && (
        <h3 className={cls.balance}>
          $
          {' '}
          {getFormatNumber(currentUser.balance)}
        </h3>
      )}
      {Number(profileId) !== currentUser.id && (
        <>
          {!user?.is_follow ? (
            <Button
              className={cls.subscribeButton}
              onClick={handleSubscribe}
              disabled={subscribeLoading}
            >
              Подписаться
            </Button>
        ) : (
          <Button
            className={cls.subscribeButton}
            theme='outlined-white'
            onClick={handleUnsubscribe}
            disabled={unsubscribeLoading}
          >
            Отписаться
          </Button>
        )}
        </>
      )}

      {open === 'subscribers' && (
        <SubscribersModal
          isOpen
          onClose={handleClose}
        >

        </SubscribersModal>
      )}
      {open === 'subscriptions' && (
        <SubscriptionsModal
          isOpen
          onClose={handleClose}
        >

        </SubscriptionsModal>
      )}
    </div>
  );
});
