import { memo, useCallback, useState } from 'react';
import SecondLogoIcon from '@assets/icons/second-logo.svg';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import cls from './Header.module.scss';
import { HeaderNotAuthContent } from './HeaderNotAuthContent/HeaderNotAuthContent';
import { HeaderAuthContent } from './HeaderAuthContent/HeaderAuthContent';
import { Container } from '../../Container';

interface HeaderProps {
  className?: string;
}

export const Header = memo((props: HeaderProps) => {
  const { className } = props;
  const user = useSelector((state) => state.auth?.user);

  return (
    <header className={classNames(cls.Header, className)}>
      <Container>
        <div className={cls.content}>
          {user ? <HeaderAuthContent /> : <HeaderNotAuthContent />}
        </div>
      </Container>
    </header>
  );
});
