import { memo, useCallback, useState } from "react";
import { Modal } from "@newComponents/shared/Modal/Modal";
import classNames from 'classnames';
import cls from './MarketAuctionBidModal.module.scss';
import { BACKEND_BASE_URL } from "@constants/constants";
import { Input } from "@newComponents/shared/Input/Input";
import { useSelector } from "react-redux";
import { Button } from "@newComponents/shared/Button/Button";
import toast from "react-hot-toast";

interface MarketAuctionBidModalProps {
  card: {
    circulation_number: number;
    image_card: string;
    price: number;
    id: number
  };
  onClose: () => void;
  className?: string;
}

export const MarketAuctionBidModal = memo((props: MarketAuctionBidModalProps) => {
  const { card, onClose, className } = props;
  const [priceValue, setPriceValue] = useState('');
  const currentUserBalance = useSelector((state) => state.auth.user.balance)

  const handleChangePriceValue = useCallback((value: string) => {
    setPriceValue(value);
  }, [])

  const handlePlaceBid = useCallback(() => {
    toast.success('The bet was successfully placed');
    onClose();
  }, [])

  return (
    <Modal
      contentClassName={classNames(cls.MarketAuctionBidModal, className)}
      isOpen
      theme="grey_opacity"
      onClose={onClose}
    >
      <img
        className={cls.image}
        src={BACKEND_BASE_URL + card.image_card}
        alt="CARD"
      />
      <div className={cls.content}>
        <div className={cls.auctionDataContent}>
          <h3 className={cls.title}>
            Place your bid
          </h3>
          <Input
            className={cls.input}
            type="number"
            value={priceValue}
            onChange={handleChangePriceValue}
          />
          <div className={cls.balanceBlock}>
            <h4 className={cls.balanceTitle}>
              Wallet balance
            </h4> 
            <span className={cls.balanceCount}>
              {currentUserBalance}
            </span>
          </div> 
        </div>
        <Button
          className={cls.button}
          theme="contained-green"
          disabled={!priceValue}
          onClick={handlePlaceBid}
        >
          Place a bid
        </Button>
      </div>
    </Modal>
  )
})