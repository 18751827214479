import { InputHTMLAttributes } from 'react';

type FilterType = 'checkbox' | 'input' | 'radio';
type InputHTML = InputHTMLAttributes<HTMLInputElement>

interface CheckboxItem extends InputHTML {
  label: string;
}

export interface MarketFilter<T extends FilterType = FilterType> {
  type: T;
  name: string;
  label: string;
  items: (T extends 'checkbox' | 'radio' ? CheckboxItem : InputHTML)[];
}

export const marketFilters: MarketFilter[] = [
  {
    type: 'radio',
    name: 'rarity',
    label: 'Category',
    items: [
      {
        label: 'All',
      },
      {
        label: 'Common',
        value: 'COMMON',
      },
      {
        label: 'Advanced',
        value: 'ADVANCED',
      },
      {
        label: 'Rare',
        value: 'RARE',
      },
      {
        label: 'Unique',
        value: 'UNIQUE',
      },
      {
        label: 'Legendary',
        value: 'LEGENDARY',
      },
    ],
  },
  {
    type: 'input',
    name: 'price',
    label: 'Category',
    items: [
      {
        placeholder: 'Price from',
        value: 'price_min',
      },
      {
        placeholder: 'Price to',
        value: 'price_max',
      },
    ],
  },
  {
    type: 'radio',
    name: 'license',
    label: 'License',
    items: [
      {
        label: 'All',
      },
      {
        label: 'With license',
        value: 'true',
      },
      {
        label: 'Without license',
        value: 'false',
      },
    ],
  },
  {
    type: 'radio',
    name: 'player_type',
    label: 'Doubles Player',
    items: [
      {
        label: 'All',
      },
      {
        label: 'Pair',
        value: 'ПАРНАЯ',
      },
      {
        label: 'Solo',
        value: 'ОДИНОЧНАЯ',
      },
    ],
  },
  {
    type: 'radio',
    name: 'player_country',
    label: 'Country',
    items: [
      {
        label: 'All',
      },
      {
        label: 'Russia',
        value: 'RU',
      },
      {
        label: 'USA',
        value: 'US',
      },
      {
        label: 'Serbia',
        value: 'CS',
      },
      {
        label: 'Brazil',
        value: 'BR',
      },
    ],
  },
  {
    type: 'radio',
    name: 'player_leading_hand',
    label: 'Hand',
    items: [
      {
        label: 'All',
      },
      {
        label: 'Left hand',
        value: 'left',
      },
      {
        label: 'Right hand',
        value: 'right',
      },
    ],
  },
  {
    type: 'radio',
    name: 'gender',
    label: 'Sex',
    items: [
      {
        label: 'All',
      },
      {
        label: 'Male',
        value: 'МУЖСКАЯ',
      },
      {
        label: 'Female',
        value: 'ЖЕНСКАЯ',
      },
    ],
  },
];
