import { memo } from 'react';
import classNames from 'classnames';
import { Tournament } from '@services/tournaments.service';
import cls from './TournamentsList.module.scss';
import { TournamentCard } from '../../TournamentCard';
import { EmptyListError } from '@newComponents/ui/EmptyListError';

interface TournamentsListProps {
  isInstruction?: boolean;
  tournaments: Tournament[];
  className?: string;
}

export const TournamentsList = memo((props: TournamentsListProps) => {
  const { isInstruction, tournaments, className } = props;
  
  return (
    <ul className={classNames(cls.TournamentsList, className)}>
      {tournaments.length > 0 ? tournaments.map((item, index) => (
        <li
          key={item.id}
          className={classNames(cls.item, isInstruction && index === 0 && cls.instructionItem)}
        >
          <TournamentCard isInstruction={isInstruction} tournament={item} />
        </li>
      )) : (
        <EmptyListError text='Tournament list is empty' />
      )}
    </ul>
  );
});
