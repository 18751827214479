import { BACKEND_URL } from "@constants/constants";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from "./AuthService";
import { getStandartBaseQuery } from '../config/getStandartBaseQuery';

interface PhoneCode {
  country: string;
  country_code: string;
  flag: string;
  mask: string;
  phone_code: string
}

export const phoneCodesAPI = createApi({
  reducerPath: 'phoneCodesAPI',
  baseQuery: getStandartBaseQuery,
  endpoints: (builder) => ({
    fetchPhoneCodes: builder.query<PhoneCode[], void>({
      query: () => '/phone-codes/'
    }) 
  })
})

export const {
  useFetchPhoneCodesQuery
} = phoneCodesAPI;