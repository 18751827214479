import { memo } from 'react';
import classNames from 'classnames';
import cls from './MarketNotFound.module.scss';
import { Button } from '../../../../shared/Button/Button';

interface MarketNotFoundProps {
  className?: string;
}

export const MarketNotFound = memo((props: MarketNotFoundProps) => {
  const { className } = props;

  return (
    <div className={classNames(cls.MarketNotFound, className)}>
      <h2 className={cls.title}>
        You don't have any cards for sale
      </h2>
      <p className={cls.description}>
        To sell a card, go to the card sales section.
      </p>
      <Button
        className={cls.button}
        theme="contained-green"
      >
        Sell card
      </Button>
    </div>
  );
});
