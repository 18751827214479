import { memo } from 'react';
import classNames from 'classnames';
import mockTournamentImage from '@assets/images/mock-tournament.jpg';

import { BACKEND_BASE_URL } from '@constants/constants';
import cls from './TournamentCardImageBlock.module.scss';
import getFormatDateTime from '@utils/formatDateTime';

type TournamentCardImageBlockSize = 'small' | 'medium'

interface PartOfTournamentType {
  image: null | string;
  category: string;
  date_start: string;
  name: string;
}

interface TournamentCardImageBlockProps {
  tournament: PartOfTournamentType;
  size?: TournamentCardImageBlockSize;
  isLive: boolean;
  className?: string;
}

export const TournamentCardImageBlock = memo((props: TournamentCardImageBlockProps) => {
  const { tournament, size = 'medium', isLive, className } = props;

  return (
    <div
      className={classNames(cls.TournamentCardImageBlock, className, cls[size])}
      style={
        {
          backgroundImage: `url(${tournament.image ? BACKEND_BASE_URL + tournament.image : mockTournamentImage})`
        }
      }
    >
      <div className={cls.created}>
        {getFormatDateTime(tournament.date_start)}
      </div>
      <div className={cls.category}>
        {tournament.category}
      </div>
      <h3 className={cls.name}>
        {tournament.name}
      </h3>
      {isLive && (
        <h3 className={cls.liveInfo}>
          LIVE!
        </h3>
      )}
    </div>
  );
});
