import axios from 'axios';
// @ts-ignore
import window from 'global';
import { UserModel } from 'models/User';
import { BACKEND_URL } from '@constants/constants';

export type TUpdateUserForm = {
    nickname: string,
    first_name: string,
    last_name: string,
    avatar: string,
    city: string,
    country: string,
    email: string,
    phone: string,
}

class AuthService {
  setUserInLocalStorage(data: UserModel) {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      localStorage.setItem('user', JSON.stringify(data));
    }
  }

  async login(username: string, password: string, country_code?: string): Promise<UserModel> {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const response = await axios.post(`${BACKEND_URL}/account/login/`, {
        username, password, country_code,
      });
      if (!response.data.access_token) {
        return response.data;
      }
      this.setUserInLocalStorage(response.data);
      if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
        localStorage.removeItem('refId');
      }
      return response.data;
    }
  }

  async send_code(
    formData: { phone: string,
        country_code: string,
        email: string },
    form_type: string,
  ) {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      let response = null;
      const { phone, email, country_code } = formData;
      if (form_type === 'email') {
        response = await axios.post(`${BACKEND_URL}/account/send_code/ordinary/`, { email, form_type, country_code });
        localStorage.setItem('recipient', email);
        localStorage.setItem('auth_message', `Confirmation code sent to ${email}`);
      } else {
        response = await axios.post(`${BACKEND_URL}/account/send_code/ordinary/`, { phone, form_type, country_code });
        localStorage.setItem('recipient', phone);
        localStorage.setItem('country_code', country_code);
        localStorage.setItem('auth_message', `Confirmation code sent to ${phone}`);
      }
      return response;
    }
  }

  async send_register_code(formData: { phone: string, country_code: string, email: string }, form_type: string) {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      let response = null;
      const { phone, email, country_code } = formData;

      if (form_type === 'email') {
        response = await axios.post(`${BACKEND_URL}/account/send_code/register/`, { email, form_type });
        localStorage.setItem('recipient', email);
        localStorage.setItem('auth_message', `Confirmation code sent to ${email}`);
      } else {
        response = await axios.post(`${BACKEND_URL}/account/send_code/register/`, { phone, country_code, form_type });
        localStorage.setItem('recipient', phone);
        localStorage.setItem('country_code', country_code);
        localStorage.setItem('auth_message', `Confirmation code sent to ${phone}`);
      }

      return response;
    }
  }

  async register_two(password: string) {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const recipient = localStorage.getItem('recipient');
      const country_code = localStorage.getItem('country_code');

      try {
        return await axios.post(`${BACKEND_URL}/account/registration/ `, {
          recipient,
          country_code,
          password,
        });
      } catch (error) {
        console.error('Error register', error);
      }
    }
  }

  async confirm_code(code: string) {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const recipient = localStorage.getItem('recipient');
      const country_code = localStorage.getItem('country_code');
      const response = await axios.post(`${BACKEND_URL}/account/confirm_code/`, { code, recipient, country_code });
      return response.data;
    }
  }

  async reset_password(old_password: string, new_password: string) {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const recipient = localStorage.getItem('recipient');
      try {
        return await axios.post(`${BACKEND_URL}/account/update_password/ `, {
          recipient,
          old_password,
          new_password,
        });
      } catch (error) {
        console.error('Error register', error);
      }
    }
  }

  logout() {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      localStorage.removeItem('user');
    }
  }

  getCurrentUser() {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const user = localStorage.getItem('user')!;
      return JSON.parse(user);
    }
  }

  async update_user(data: any) {
    let response = null;
    const headers = { Authorization: `Bearer ${this.getCurrentUser().token}` };
    try {
      response = await axios.patch(
        `${BACKEND_URL}/account/profile/${this.getCurrentUser().id}/`,
        data,
        { headers },
      );
    } catch (error) {
      if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
        localStorage.setItem('auth_error', `${error}`);
      }
      console.error('Error register', error);
    }
    return response;
  }
}

export default new AuthService();
