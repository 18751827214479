import { BACKEND_URL } from '@constants/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from './AuthService';
import { Rarity, Translations } from './cards.service';
import { getStandartBaseQuery } from '../config/getStandartBaseQuery';

export interface MysteryCase {
  amount: number;
  id: number;
  mystery_box: MysteryCase;
}

type OpenMysteryCaseTypes = 'nft_card' | 'crew' | 'item';

type FetchAllCasesResponse = MysteryCase[];

export interface MysteryCaseDrop {
  image_card?: string;
  name: string | Translations;
  type?: OpenMysteryCaseTypes;
  rarity: Rarity
}

export interface MysteryCaseInfo {
  drops: MysteryCaseDrop[];
  id: number;
  image: string;
  price: string;
  rarity_type: string;
}

interface OpenMysteryCaseNFTCard {
  circulation_number: number;
  id: number;
  image_card: string;
  type: OpenMysteryCaseTypes;
}

interface OpenMysteryCaseItem {
  circulation_number: number;
  id: number;
  image: null | string;
  type: OpenMysteryCaseTypes;
  translations: Translations
}

export const mysteryAPI = createApi({
  reducerPath: 'mysteryAPI',
  baseQuery: getStandartBaseQuery,
  endpoints: (builder) => ({
    fetchAllCases: builder.query<FetchAllCasesResponse, void>({
      query: () => ({
        url: '/market/products/mysteryboxes/?ordering=price',
      }),
    }),
    fetchMysteryCase: builder.query<MysteryCaseInfo, string>({
      query: (mysteryId) => ({
        url: `/market/mystery_box/${mysteryId}/`,
      }),
    }),
    openMysteryCase: builder.mutation<object, string>({
      query: (mysteryId) => ({
        url: `/wallet/mystery_box/${mysteryId}/`,
        method: 'POST',
      }),
    }),
    fetchAllPacks: builder.query({
      query: () => ({
        url: '/market/products/packs/'
      })
    }),
    fetchPackInfo: builder.query<void, string>({
      query: (packId) => ({
        url: `/market/pack/${packId}/`
      })
    }),
    openPack: builder.mutation<void, string>({
      query: (packId) => ({
        url: `/wallet/pack/${packId}/`,
        method: 'POST'
      })
    })
  }),
});

export const {
  useFetchAllCasesQuery,
  useFetchMysteryCaseQuery,
  useOpenMysteryCaseMutation,
  useFetchAllPacksQuery,
  useFetchPackInfoQuery,
  useOpenPackMutation,
} = mysteryAPI;
