import { memo, useCallback } from 'react';
import classNames from 'classnames';
import { type TeamType } from '@services/tournaments.service';

import EditIcon from '@assets/icons/edit.svg';
import BasketIcon from '@assets/icons/basket.svg';
import { BACKEND_BASE_URL } from '@constants/constants';
import cls from './Team.module.scss';

import TeamEmptyPlayerImage from '@assets/images/team-empty-player.png';
import { Button } from '@newComponents/shared/Button/Button';
import { useMenu } from '@hooks/useMenu';
import { ChangeTeamPlayerModal } from '@newComponents/ui/ChangeTeamPlayerModal';

interface TeamProps {
  team: TeamType;
  isInstruction?: boolean;
  isNotEditable?: boolean;
  select?: {
    isSelected: boolean;
    onSelectTeam: (teamId: number) => void;
  }
  className?: string;
}

const fullTeamPlayersCount = 8;

export const Team = memo((props: TeamProps) => {
  const { team, isNotEditable, isInstruction, select, className } = props;
  const {open, handleOpen, handleClose} = useMenu();

  const isSelected = select?.isSelected;
  const onSelectTeam = select?.onSelectTeam;

  const renderMissingPlayers = useCallback(() => {
    const missingPlayersCount = fullTeamPlayersCount - team.players.length;
    if (missingPlayersCount === 0) return;
    return new Array(missingPlayersCount).fill(<img className={cls.playerImage} src={TeamEmptyPlayerImage} />);
  }, [team.players])

  return (
    <div className={classNames(cls.Team, className)}>
            <div className={cls.textInfo}>
        <div className={cls.nameBlock}>
          <h3 className={cls.name}>
            {team.name}
          </h3>
        </div>
        <div className={cls.controlBlock}>
          {!select && !isNotEditable && (
            <div className={cls.deleteBlock}>
              <div className={classNames(cls.iconBlock, cls.basketIcon)}>
                <BasketIcon />
              </div>
              <span className={cls.deleteText}>
                Удалить команду
              </span>
            </div>
          )}
          {isInstruction && (
            <Button
              theme="contained-grey"
              className={cls.button}
              onClick={() => onSelectTeam(team.id)}
            >
              Team selected
            </Button>
          )}
          {select && (
            <Button
              theme={!isSelected ? "contained-green" : "contained-grey"}
              className={cls.button}
              onClick={() => onSelectTeam(team.id)}
            >
              {!isSelected ? 'Select team' : 'Team selected'}
            </Button>
          )}
        </div>
      </div>
      <div className={cls.teamInfo}>
        <ul className={cls.playersList}>
          {team.players.map((player) => (
            <li className={cls.playerItem} key={player.id}>
              <img
                src={BACKEND_BASE_URL + player.image_card}
                className={classNames(cls.playerImage, player.trauma && cls.trauma, !isInstruction && !isNotEditable && cls.canHover)}
                onClick={!isInstruction && !isNotEditable ? () => handleOpen(player.id) : null}
              />
              {open === player.id && (
                <ChangeTeamPlayerModal
                  teamId={team.id}
                  player={player}
                  onClose={handleClose}
                />
              )}
            </li>
          ))}
          {renderMissingPlayers()}
        </ul>
      </div>
    </div>
  );
});
