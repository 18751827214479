import { Suspense, useEffect, useState } from 'react';
import axios from 'axios';
import {
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import './vendor';
import { AppRouter } from '@routes/AppRouter';
import { Layout } from './newComponents/layout/Layout';
import { Toaster } from 'react-hot-toast';

export const App = () => {
  axios.interceptors.request.use((config) => {
    config.headers['Accept-Language'] = localStorage.getItem('i18nextLng');
    return config;
  });
  console.log('render')
  const [searchParams] = useSearchParams();
  const [isRegisterTournament, setIsRegisterTournament] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const referalId = searchParams.get('ref');
    if (referalId && typeof localStorage !== 'undefined' && !localStorage.getItem('user')) {
      localStorage.setItem('refId', referalId);
    }
  }, []);

  return (
    <div className="bg-[rgb(16,16,16)]">
      <Layout>
        <Toaster position='top-center' />
          <AuthContextProvider>
            <div style={{ minHeight: location.pathname !== '/dino' && location.pathname !== '/tennis' && '100vh' }}>
              <Suspense fallback="">
                <AppRouter
                  setIsRegisterTournament={setIsRegisterTournament}
                  isRegisterTournament={isRegisterTournament}
                />
              </Suspense>
            </div>
          </AuthContextProvider>
      </Layout>
    </div>
  );
};
