import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { MysteryCaseInfo } from '@newComponents/ui/mysteryCase/MysteryCaseInfo';
import { Page } from '../../../shared/Page/Page';
import { BackButton } from '@newComponents/shared/BackButton/BackButton';

interface MysteryCaseProps {
  className?: string;
}

export const MysteryCase = memo((props: MysteryCaseProps) => {
  const { className } = props;
  console.log('render')

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Page containerClassName={className}>
      <BackButton />
      <MysteryCaseInfo />
    </Page>
  );
});
