import { memo, useCallback, useEffect, useState } from "react";
import { useFetchPackInfoQuery, useOpenPackMutation } from "@services/mystery.service";
import { useParams } from "react-router-dom";
import classNames from 'classnames';
import cls from './MysteryPackInfo.module.scss';
import { Button } from "@newComponents/shared/Button/Button";
import { BACKEND_BASE_URL } from "@constants/constants";

interface MysteryPackInfoProps {
  className?: string;
}

enum Status {
  DEFAULT = 'default',
  OPENED = 'opened',
}

export const MysteryPackInfo = memo((props: MysteryPackInfoProps) => {
  const { className } = props;
  const { packId } = useParams<{packId: string}>()
  const {data: mysteryPackData} = useFetchPackInfoQuery(packId);
  const [openPack, {data: openedPackData, isLoading}] = useOpenPackMutation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);

  const handleOpenPack = useCallback(async () => {
    setStatus(Status.DEFAULT)
    try {
      await openPack(packId).unwrap();
      setStatus(Status.OPENED);
    } catch (error) {
      console.log(error);
    }
  }, [openPack, packId])

  const handleReset = useCallback(() => {
    setStatus(Status.DEFAULT);
  }, [])

  if (mysteryPackData) {
    return (
      <div className={classNames(cls.MysteryPackInfo, className)}>
        {status === Status.DEFAULT ? (
          <>
            <img
              className={cls.image}
              src={BACKEND_BASE_URL + mysteryPackData.image}
              alt={mysteryPackData.rarity}
            />
            <div className={cls.controlContent}>
            <h3 className={cls.name}>
              {mysteryPackData.rarity}
            </h3>
            <span className={cls.price}>
              {mysteryPackData.price}
                $
            </span>
            <Button
              className={cls.button}
              theme="contained-green"
              onClick={handleOpenPack}
            >
              Открыть пак
            </Button>
            </div>
          </>
        ) : (
          <div className={classNames(cls.slideIn, cls.fadeIn)}>
            {openedPackData && (
              <>
                <ul className={cls.list}>
                  {openedPackData.crew_cards.map((crewCard) => (
                    <>
                      {crewCard.image_card && (
                        <li className={cls.item}>
                          <img
                            className={cls.image}
                            src={BACKEND_BASE_URL + crewCard.image_card}
                          />
                        </li>
                      )}
                    </>
                  ))}
                  {openedPackData.items.map((item) => (
                    <>
                      {item.image_card && (
                        <li className={cls.item}>
                          <img
                            className={cls.image}
                            src={BACKEND_BASE_URL + item.image_card}
                          />
                        </li>
                      )}
                    </>
                  ))}
                  {openedPackData.nft_cards.map((nftCard) => (
                    <>
                      {nftCard.image_card && (
                        <li className={cls.item}>
                          <img
                            className={cls.image}
                            src={BACKEND_BASE_URL + nftCard.image_card}
                          />
                        </li>
                      )}
                    </>
                  ))}
                </ul>
                <h3 className={classNames(cls.name, cls.openedName)}>
                  {mysteryPackData.rarity}
                </h3>
                <div className={cls.buttons}>
                  <Button
                    className={cls.button}
                    theme="contained-green"
                    onClick={handleOpenPack}
                  >
                    Open pack again
                  </Button>
                  <Button
                    className={cls.button}
                    theme="contained-grey"
                    onClick={handleReset}
                  >
                    Close
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    )
  }
})