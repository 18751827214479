import { memo } from "react";
import { MysteryPackInfo } from "@newComponents/ui/MysteryPackInfo";
import classNames from 'classnames';
import cls from './MysteryPack.module.scss';
import { BackButton } from "@newComponents/shared/BackButton/BackButton";

interface MysteryPackProps {
  className?: string;
}

export const MysteryPack = memo((props: MysteryPackProps) => {
  const { className } = props;
  console.log('render')

  return (
    <div className={classNames(className)}>
      <BackButton />
      <MysteryPackInfo />
    </div>
  )
})