import { Modal } from "@newComponents/shared/Modal/Modal";
import { memo } from "react";
import classNames from 'classnames';
import cls from './SubscriptionsModal.module.scss';
import { useFetchUserSubscriptionsQuery } from "@services/users.service";
import { useParams } from "react-router-dom";
import { ProfileFriendCard } from "../../ProfileFriendCard";

interface SubscriptionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

export const SubscriptionsModal = memo((props: SubscriptionsModalProps) => {
  const { isOpen, onClose, className } = props;
  const { profileId } = useParams<{profileId: string}>();
  const {data} = useFetchUserSubscriptionsQuery(profileId);

  return (
    <Modal
      contentClassName={classNames(cls.SubscriptionsModal, className)}
      isOpen={isOpen}
      onClose={onClose}
    >
      <h3 className={cls.title}>
        Подписки
      </h3> 
      <ul className={cls.list}>
        {data && data.map((user) => (
          <li className={cls.item} key={user.id}>
            <ProfileFriendCard user={user} />
          </li>
        ))}
      </ul> 
    </Modal>
  )
})