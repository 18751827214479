import { Page } from '@newComponents/shared/Page/Page';
import { memo, useEffect } from 'react';
import classNames from 'classnames';
import { BackButton } from '@newComponents/shared/BackButton/BackButton';
import { TournamentGridResults } from '@newComponents/ui/tournaments/TournamentGridResults';
import cls from './TournamentGrid.module.scss';

interface TournamentGridProps {
  className?: string;
}

export const TournamentGrid = memo((props: TournamentGridProps) => {
  const { className } = props;
  console.log('render')

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Page containerClassName={classNames(cls.TournamentGrid, className)}>
      <BackButton
        className={cls.backButton}
        to="/tournaments"
      />
      <TournamentGridResults />
    </Page>
  );
});
