import { Page } from "@newComponents/shared/Page/Page";
import { memo, useEffect, useMemo } from "react";
import classNames from 'classnames'
import cls from './FriendlyTournament.module.scss';
import { BackButton } from "@newComponents/shared/BackButton/BackButton";
import { TournamentLiveInfo } from "@newComponents/ui/tournaments/tournamentPage/TournamentLiveInfo";
import { FriendlyTournamentExpectation } from "@newComponents/ui/tournaments/FriendlyTournamentExpectation";
import { useFetchCurrentFriendlyTournamentQuery } from "@services/tournaments.service";
import { FriendlyMatchLiveInfo } from "@newComponents/ui/tournaments/tournamentPage/FriendlyMatchLiveInfo";

interface FriendlyTournamentProps {
  className?: string;
}

export const FriendlyTournament = memo((props: FriendlyTournamentProps) => {
  const { className } = props;
  const {data, isLoading} = useFetchCurrentFriendlyTournamentQuery(null, {
    pollingInterval: 5000
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const isExpectationFriendlyMatch = useMemo(() => (
    data && new Date(data.date_end) > new Date()
  ), [data]);

  return (
    <Page
      containerClassName={classNames(cls.FriendlyTournament, className)}
    >
      <div className={cls.background}/>
      <div className={cls.content}>
        <BackButton
          className={cls.backButton}
          to="/tournaments"
          text="123"
        />
        {!isLoading && !data && (
          <h3 className={cls.notTournament}>
            No friendly tournament yet
          </h3>
        )}
        {data?.status_friendly_match === 'НЕ НАЧАЛСЯ' && (
          <FriendlyTournamentExpectation
            dateStart={data?.date_start ? new Date(data?.date_start) : null}
            tournamentId={data.id}
          />
        )}
        {data?.status_friendly_match === 'ИДЕТ' && <FriendlyMatchLiveInfo />}
      </div>
    </Page>
  );
})