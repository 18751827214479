import { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import UpArrowIcon from '@assets/icons/up-arrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { marketActions } from '@store/slices/market';
import { useSearchParams } from 'react-router-dom';
import cls from './MarketFilters.module.scss';
import { Checkbox } from '../../../../shared/Checkbox/Checkbox';
import { Radio } from '../../../../shared/Radio/Radio';
import { Input } from '../../../../shared/Input/Input';
import { type MarketFilter, marketFilters } from '../config/market';

interface MarketFiltersProps {
  className?: string;
}

export const MarketFilters = memo((props: MarketFiltersProps) => {
  const { className } = props;
  const [openedFilters, setOpenedFilters] = useState<string[]>([]);
  const dispatch = useDispatch();
  const filtersQuery = useSelector((state) => state?.market?.filters);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpenFilter = useCallback((name: string) => {
    setOpenedFilters((prevState) => [...prevState, name]);
  }, []);

  const handleCloseFilter = useCallback((name: string) => {
    setOpenedFilters((prevState) => prevState.filter((filter) => filter !== name));
  }, []);

  const handleChangeFilterQuery = useCallback((name: string, value: string = '') => {
    dispatch(marketActions.setUpdateFilters({
      [name]: value,
    }));
  }, [dispatch]);

  const getIsOpenedFilter = useCallback((name: string) => openedFilters.includes(name), [openedFilters]);

  return (
    <div className={classNames(cls.MarketFilters, className)}>
      <ul className={cls.list}>
        {marketFilters.map((filter) => (
          <li key={filter.name} className={cls.item}>
            {(filter.type === 'radio'
              || filter.type === 'checkbox') && (
              <div className={classNames(
                cls.checkboxBlock,
                getIsOpenedFilter(filter.name) && cls.opened,
              )}
              >
                <div
                  className={cls.checkboxHeader}
                  onClick={!getIsOpenedFilter(filter.name) ? () => handleOpenFilter(filter.name) : () => handleCloseFilter(filter.name)}
                >
                  <h3 className={cls.checkboxTitle}>
                    {filter.label}
                  </h3>
                  <UpArrowIcon />
                </div>
                {getIsOpenedFilter(filter.name) && (
                  <div className={cls.controlBlock}>
                    {filter.items.map((item, index) => (
                      <Radio
                        key={item.placeholder || item?.label || index}
                        className={cls.checkbox}
                        isLeftLabel
                        name={filter.name}
                        label={item.label}
                        value={item.value}
                        checked={filtersQuery[filter.name] === item.value || (!filtersQuery[filter.name] && !item.value)}
                        onChange={handleChangeFilterQuery}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
            {filter.type === 'input' && (
              <div className={cls.inputs}>
                {filter.items.map((item, index) => (
                  <Input
                    key={`${item.placeholder}_${index}`}
                    className={cls.inputBlock}
                    inputClassName={cls.input}
                    placeholder={item.placeholder}
                  />
                ))}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
});
