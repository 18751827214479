import { memo } from 'react';
import classNames from 'classnames';
import { Button } from '@newComponents/shared/Button/Button';
import CalendarIcon from '@assets/icons/calendar.svg';
import { Link } from 'react-router-dom';
import cls from './TournamentsInfoHeader.module.scss';
import { useWindowSize } from '@hooks/useWindowSize';

interface TournamentsInfoHeaderProps {
  className?: string;
}

export const TournamentsInfoHeader = memo((props: TournamentsInfoHeaderProps) => {
  const { className } = props;
  const { width } = useWindowSize();

  return (
    <div className={classNames(cls.TournamentsInfoHeader, className)}>
      <h3 className={cls.title}>
        Highlights
      </h3>
      <Link to="/tournaments/calendar">
        <Button className={cls.button} theme="contained-grey">
          <CalendarIcon />
          {width > 768 && <span>Calendar of events</span>}
        </Button>
      </Link>
    </div>
  );
});
