import {
  ChangeEvent, InputHTMLAttributes, memo, useCallback,
} from 'react';
import classNames from 'classnames';
import './Radio.scss';

type InputHTML = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>

interface RadioProps extends InputHTML {
  label?: string;
  isLeftLabel: boolean;
  isRightLabel: boolean;
  onChange?: (name: string, value: string) => void;
}

export const Radio = memo((props: RadioProps) => {
  const {
    value,
    name,
    label,
    checked,
    id,
    className,
    isLeftLabel,
    isRightLabel,
    onChange,
    ...rest
  } = props;

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.name, e.target.value);
  }, [onChange]);

  return (
    <label className={classNames('checkbox-container', className)}>
      {isLeftLabel && (
        <p className="label">{label}</p>
      )}
      <input
        type="radio"
        checked={checked}
        className="checkbox-input"
        name={name}
        value={value}
        onChange={handleChange}
        {...rest}
      />
      <span className="checkbox-checkmark">
        <div className="color-block" />
      </span>
      {isRightLabel && (
        <p className="label">{label}</p>
      )}
    </label>
  );
});
