import { Box, Html, OrbitControls } from '@react-three/drei';
import { Canvas, useLoader } from '@react-three/fiber';

import { TextureLoader } from 'three';
import './CardPage.scss';
import { useLocation, useParams } from 'react-router-dom';
import { BackCard, NewCharacteristicCard } from '@components/NewCharacteristicCard';
import {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import domtoimage from 'dom-to-image';
import axios from 'axios';
import { BACKEND_BASE_URL, BACKEND_URL } from '@constants/constants';
import { AuthContext } from '@context/AuthContext';
import { PlayerCard } from '@components/PlayerCard';
import { useWindowSize } from '@hooks/useWindowSize';

const getCardColor = (rarity: string) => {
  console.log('rarity', rarity);
  switch (rarity) {
  case 'ADVANCED':
    return 'rgb(0,101,23)';
  case 'UNIQUE':
    return 'rgb(181,101,0)';
  case 'LEGENDARY':
    return 'rgb(154,2,2)';
  case 'RARE':
    return 'rgb(0,20,217)';
  case 'COMMON':
    return '#FFFFFF';
  }
};

const Card = ({ frontTexture, backTexture, color }) => (
  <Box args={[3.8, 6, 0.25]} position={[0, 0.2, 0]} receiveShadow>
    <meshStandardMaterial color={color} roughness={0.2} metalness={0.8} emissive={color} />
    <mesh position={[0, 0, 0.13]} receiveShadow castShadow>
      <planeGeometry args={[3.8, 6]} />
      <meshStandardMaterial map={frontTexture} roughness={0.1} metalness={0.8} />
    </mesh>
    <mesh position={[0, 0, -0.13]} rotation={[0, Math.PI, 0]} receiveShadow castShadow>
      <planeGeometry args={[3.8, 6]} />
      <meshStandardMaterial map={backTexture} roughness={0.1} metalness={0.8} />
    </mesh>

    <Html position={[0, 0, 0.1]} transform style={{ position: 'absolute', top: '-105px', left: '-55px' }}>
      {/* Ваше содержимое HTML здесь */}
    </Html>
  </Box>
);

export const CardPage = ({rarity, frontImage, backImage, frontImageLocal, backImageLocal}) => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const cardColor = useMemo(() => getCardColor(rarity), []);
  const { width } = useWindowSize();

  return (
    <div style={{ width: '60%', height: '70vh' }}>
      {frontImage && backImage && cardColor && (
        <Canvas shadows style={{ overflow: 'visible' }}>
          <ambientLight intensity={21} />
          <directionalLight position={[10, 10, 10]} intensity={2} castShadow />
          <pointLight position={[0, 0, -5]} intensity={2} castShadow />
          <spotLight position={[5, 5, 5]} intensity={1.5} angle={0.2} penumbra={1} castShadow />
          <Card
            color={cardColor}
            frontTexture={useLoader(TextureLoader, BACKEND_BASE_URL + frontImage)}
            backTexture={useLoader(TextureLoader, BACKEND_BASE_URL + backImage)}
          />
          {location.pathname.includes('/profile/') ? (
            <OrbitControls minDistance={4} maxDistance={4} />
          ) : (
            <OrbitControls minDistance={6} maxDistance={6} />
          )}
        </Canvas>
      )}
    </div>
  );
};
