import { memo, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@newComponents/shared/Button/Button";
import { getFormatTime, TimeInfo } from "@utils/DateUtils";
import { useCancelFriendlyMatchMutation } from "@services/tournaments.service";
import classNames from 'classnames'
import cls from './FriendlyTournamentExpectation.module.scss';
import toast from "react-hot-toast";

interface FriendlyTournamentExpectationProps {
  dateStart?: Date;
  tournamentId: number;
  className?: string;
}

function calculateTimeLeft(targetTime: Date): TimeInfo {
  const now = new Date();
  const difference = targetTime - now;
  let timeLeft: TimeInfo = {
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft = {
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  }

  return timeLeft;
}

export const FriendlyTournamentExpectation = memo((props: FriendlyTournamentExpectationProps) => {
  const { dateStart, tournamentId, className } = props;
  const [timeInfo, setTimeInfo] = useState<TimeInfo>(calculateTimeLeft(dateStart));
  const timerRef = useRef() as MutableRefObject<ReturnType<typeof setTimeout>>;
  const [cancelFriendlyMatch] = useCancelFriendlyMatchMutation();

  const updateTimer = () => {
    setTimeInfo(calculateTimeLeft(dateStart));
    timerRef.current = setTimeout(updateTimer, 1000);
  };

  const handleCancelFriendlyMatch = useCallback(async () => {
    try {
      // Вызов API через мутацию
      await cancelFriendlyMatch(tournamentId).unwrap();
      
      // Показ тоста об успешной отмене
      toast.success("The friendly match has been successfully canceled!");
    } catch (error) {
      // Показ тоста об ошибке
      toast.error("Failed to cancel the friendly match. Please try again.");
    }
  }, [cancelFriendlyMatch, tournamentId]);

  useEffect(() => {
    updateTimer();

    return () => {
      if (timerRef.current) {
        console.log('callback');
        clearTimeout(timerRef.current);
      }
    };
  }, [dateStart]);

  return (
    <div className={classNames(cls.FriendlyTournamentExpectation, className)}>
      <div className={cls.content}>
        {dateStart && (
          <div className={cls.timerBlock}>
            {getFormatTime(timeInfo)}
          </div>
        )}
        <h3 className={cls.title}>
          {!dateStart ? 'Waiting for the opponent' : 'The match will start soon'}
        </h3>
        <p className={cls.description}>
          {!dateStart ? 'The challenge has been sent to your opponent. It is necessary to wait for him to accept it and prepare the team for the competition.' : 'A friendly match lasts 5 minutes'}
        </p>
        <div className={cls.buttons}>
          <Button
            className={cls.button}
            theme="contained-purple"
            onClick={handleCancelFriendlyMatch}
          >
            Cancel the match
          </Button>
          <Button className={cls.button} theme="contained-green">
            Edit team
          </Button>
        </div>
      </div>
    </div>
  )
})