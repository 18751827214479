import { memo, Suspense, useEffect } from 'react';
import classNames from 'classnames';
import {
  Link, Route, Routes, useLocation,
} from 'react-router-dom';
import { PageLoader } from '@newComponents/ui/PageLoader';
import cls from './Market.module.scss';
import { Page } from '../../../shared/Page/Page';
import { MarketPlayers } from '../../../ui/market/MarketPlayers';
import { MysteryCases } from '../../../ui/market/MysteryCases';
import { MarketItems } from '../../../ui/market/MarketItems';
import { MysteryPacks } from '@newComponents/ui/market/MysteryPacks';
import { MarketAuction } from '@newComponents/ui/market/MarketAuction';

interface MarketProps {
  className?: string;
}

export const Market = memo((props: MarketProps) => {
  const { className } = props;
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Page
      className={classNames(cls.Market, className)}
      containerClassName={cls.MarketContainer}
    >
      <ul className={cls.tabs}>
        <li
          className={classNames(cls.tabItem, pathname.includes('/market/cards') && cls.active)}
        >
          <Link className={cls.link} to="/market/cards">
            Cards
          </Link>
        </li>
        <li
          className={classNames(cls.tabItem, pathname.includes('/market/mystery') && cls.active)}
        >
          <Link className={cls.link} to="/market/mystery">
            Mystery Cases
          </Link>
        </li>
        <li
          className={classNames(cls.tabItem, pathname.includes('/market/packs') && cls.active)}
        >
          <Link className={cls.link} to="/market/packs">
            Mystery Pack
          </Link>
        </li>
        <li
          className={classNames(cls.tabItem, pathname.includes('/market/items') && cls.active)}
        >
          <Link className={cls.link} to="/market/items">
            Items
          </Link>
        </li>
        <li
          className={classNames(cls.tabItem, pathname.includes('/market/auction') && cls.active)}
        >
          <Link className={cls.link} to="/market/auction">
            Auction
          </Link>
        </li>
      </ul>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path="cards" element={<MarketPlayers />} />
          <Route path="mystery" element={<MysteryCases />} />
          <Route path="packs" element={<MysteryPacks />} />
          <Route path="items" element={<MarketItems />} />
          <Route path="auction" element={<MarketAuction />} />
        </Routes>
      </Suspense>
    </Page>
  );
});
