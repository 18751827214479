import { memo, useCallback, useState } from 'react';
import { FetchPlayersCardsResponseNormalize } from '@services/cards.service';
import classNames from 'classnames';
import { MarketCard } from '../../MarketCard/ui/MarketCard';
import { MarketCardSkeleton } from '../../MarketCard/ui/MarketCardSkeleton/MarketCardSkeleton';
import cls from './MarketCardsList.module.scss';
import { MarketNotFound } from '../../MarketNotFound';
import ContainedPlusIcon from '@assets/icons/contained-plus.svg';
import { SellAuctionCardModal } from '@newComponents/ui/SellAuctionCardModal';

interface MarketCardsListProps {
  cards: FetchPlayersCardsResponseNormalize;
  isLoading: boolean;
  isFetching: boolean;
  isAuction?: boolean;
  isMyClub?: boolean;
  className?: string
}

export const MarketCardsList = memo((props: MarketCardsListProps) => {
  const {
    cards,
    isLoading,
    isMyClub,
    isFetching,
    isAuction,
    className
  } = props;
  return (
    <ul className={classNames(cls.MarketCardsList, className)}>
      {!isLoading && !isFetching ? (
        <>
          {cards && (cards?.results?.length > 0 || isAuction && isMyClub) ? cards?.results.map((item, index) => (
            <>
              {item.image_card && (
                <li key={item.id}>
                  <MarketCard
                    isMyClub={isMyClub}
                    card={item}
                    isAuction={isAuction}
                  />
                </li>
              )}
              {index + 1 === cards.results.length && isAuction && isMyClub && (
                <li className={cls.newItemBlock}>
                  <ContainedPlusIcon className={cls.newItemBlockIcon} />
                  <h3 className={cls.newItemBlockTitle}>
                    Sell a new card
                  </h3>
                </li>
              )}
            </>
          )) : (
            <MarketNotFound />
          )}
        </>
      ) : (
        <>
          {new Array(21).fill(null).map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              <MarketCardSkeleton />
            </li>
          ))}
        </>
      )}
    </ul>
  );
});
