import { BACKEND_URL } from '@constants/constants';
import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

export const getAccessToken = () => Cookies.get('access');

export const isTokenExpired = (token) => {
  if (!token) return true;
  const { exp } = jwtDecode(token);
  return Date.now() >= exp * 1000; // Умножаем на 1000 для перевода в миллисекунды
};

export const refreshAccessToken = async () => {
  try {
    await axios.get(`${BACKEND_URL}/account/refresh/`);
  } catch (error) {
    console.error('Не удалось обновить токен', error);
  }
};