import { memo } from 'react';
import classNames from 'classnames';
import cls from './MarketCardSkeleton.module.scss';
import cardCls from '../MarketCard.module.scss';
import { Skeleton } from '../../../../../shared/Skeleton/Skeleton';

interface MarketCardSkeletonProps {
  className?: string
}

export const MarketCardSkeleton = memo((props: MarketCardSkeletonProps) => {
  const { className } = props;

  return (
    <div
      className={classNames(cardCls.MarketCard, className)}
    >
      <Skeleton className={cardCls.image} border="10" />
      <div className={cardCls.priceBlock}>
        <Skeleton
          className={classNames(cls.firstPriceSkeleton)}
          border="10px"
        />
        <Skeleton
          className={classNames(cls.secondPriceSkeleton)}
          border="10px"
        />
      </div>
      <Skeleton className={cardCls.button} border="50px" />
    </div>
  );
});
