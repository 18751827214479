import { BACKEND_URL } from "@constants/constants";
import { BaseQueryFn, fetchBaseQuery } from "@reduxjs/toolkit/query";
import { getAccessToken, isTokenExpired } from "@services/token.service";
import { refreshAccessToken } from "@store/services/token";

const baseQuery = fetchBaseQuery({
  baseUrl: BACKEND_URL,
  // headers: {
  //   Authorization: typeof localStorage !== 'undefined' && `Bearer ${localStorage.getItem('token')}`
  // }
});

export const getStandartBaseQuery: BaseQueryFn = async (args, api, extraOptions) => {
  const isTokenRefreshing = api?.getState?.()?.auth.isTokenRefreshing
  if (isTokenExpired(getAccessToken()) && !isTokenRefreshing) {
      await api?.dispatch?.(refreshAccessToken());
  }

  const result = await baseQuery({ ...args }, api, extraOptions);

  return result
}
