import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@newComponents/layout/Container';
import SecondLogoIcon from '@assets/icons/second-logo.svg';
import FacebookIcon from '@assets/icons/facebook.svg';
import TwitterIcon from '@assets/icons/twitter.svg';
import InstagramIcon from '@assets/icons/instagram.svg';
import classNames from 'classnames';
import cls from './Footer.module.scss';

interface FooterProps {
  className?: string;
}

export const Footer = memo((props: FooterProps) => {
  const { className } = props;

  return (
    <Container className={cls.footerContainer}>
      <footer className={classNames(cls.footer, className)}>
        <nav className={cls.nav}>
          <ul className={cls.list}>
            <h3 className={cls.listTitle}>
              Product
            </h3>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
          </ul>
          <ul className={cls.list}>
            <h3 className={cls.listTitle}>
              Product
            </h3>
          <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
          </ul>
          <ul className={cls.list}>
            <h3 className={cls.listTitle}>
              Product
            </h3>
          <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
          </ul>
          <ul className={cls.list}>
            <h3 className={cls.listTitle}>
              Product
            </h3>
          <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
            <li className={cls.item}>
              <Link className={cls.link} to="/">
                Autocapture
              </Link>
            </li>
          </ul>
        </nav>
        <SecondLogoIcon className={cls.logo} />
        <p className={cls.companyInfo}>
          © 2022 NFT-Tennis. All rights reserved. 
        </p>
        <ul className={cls.socialMediaList}>
          <li className={cls.socialMediaItem}>
            <FacebookIcon className={cls.icon} />
          </li>
          <li className={cls.socialMediaItem}>
            <TwitterIcon className={cls.icon} />
          </li>
          <li className={cls.socialMediaItem}>
            <InstagramIcon className={cls.icon} />
          </li>
        </ul>
      </footer>
    </Container>
  );
});
