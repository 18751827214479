import { memo, Suspense, useEffect } from 'react';
import classNames from 'classnames';
import {
  Link, Route, Routes, useLocation,
} from 'react-router-dom';
import { PageLoader } from '@newComponents/ui/PageLoader';
import { Page } from '../../../shared/Page/Page';
import cls from './MyClub.module.scss';
import { ProfileTeams } from '../../../ui/profile/ProfileTeams';
import { MyClubItems } from '../../../ui/MyClubItems';
import { MyClubPlayers } from '../../../ui/MyClubPlayers';
import { MyClubAwards } from '../../../ui/MyClubAwards';
import { MyClubSales } from '@newComponents/ui/MyClubSales';
import { MyClubAuctions } from '@newComponents/ui/MyClubAuctions';
import MarketAuction from '@newComponents/ui/market/MarketAuction/ui/MarketAuction';

interface MyClubProps {
  className?: string;
}

export const MyClub = memo((props: MyClubProps) => {
  const { className } = props;
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Page
      className={classNames(cls.MyClub, className)}
      containerClassName={cls.MyClubContainer}
    >
      <ul className={cls.tabs}>
        <li
          className={classNames(cls.tabItem, pathname.includes('/my-club/teams') && cls.active)}
        >
          <Link className={classNames(cls.link, pathname.includes('/my-club/teams') && cls.active)} to="/my-club/teams">
            My Teams
          </Link>
        </li>
        <li
          className={classNames(cls.tabItem, pathname.includes('/my-club/subjects') && cls.active)}
        >
          <Link className={classNames(cls.link, pathname.includes('/my-club/subjects') && cls.active)} to="/my-club/subjects">
            My subjects
          </Link>
        </li>
        <li
          className={classNames(cls.tabItem, pathname.includes('/my-club/collections') && cls.active)}
        >
          <Link className={classNames(cls.link, pathname.includes('/my-club/collections') && cls.active)} to="/my-club/collections">
            Collections
          </Link>
        </li>
        <li
          className={classNames(cls.tabItem, pathname.includes('/my-club/awards') && cls.active)}
        >
          <Link className={classNames(cls.link, pathname.includes('/my-club/awards') && cls.active)} to="/my-club/awards">
            Awards
          </Link>
        </li>
        <li
          className={classNames(cls.tabItem, pathname.includes('/my-club/sales') && cls.active)}
        >
          <Link className={classNames(cls.link, pathname.includes('/my-club/sales') && cls.active)} to="/my-club/sales">
            My sales
          </Link>
        </li>
        <li
          className={classNames(cls.tabItem, pathname.includes('/my-club/auctions') && cls.active)}
        >
          <Link className={classNames(cls.link, pathname.includes('/my-club/auctions') && cls.active)} to="/my-club/auctions">
            My Auctions
          </Link>
        </li>
      </ul>

      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path="teams" element={<ProfileTeams className={cls.myClubTeams} />} />
          <Route path="subjects" element={<MyClubItems />} />
          <Route path="collections" element={<MyClubPlayers />} />
          <Route path="awards" element={<MyClubAwards />} />
          <Route path="sales" element={<MyClubSales />} />
          <Route path="auctions" element={<MarketAuction isMyClub />} />
        </Routes>
      </Suspense>
    </Page>
  );
});
