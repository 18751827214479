import { memo } from "react";
import classNames from 'classnames'
import cls from './ProfileFriendCard.module.scss';
import { Friend } from "@services/users.service";
import { BACKEND_BASE_URL } from "@constants/constants";
import { Avatar } from "@newComponents/shared/Avatar/Avatar";
import { Link } from "react-router-dom";

interface ProfileFriendCardProps {
  user: Friend;
  className?: string;
}

export const ProfileFriendCard = memo((props: ProfileFriendCardProps) => {
  const { user, className } = props;

  return (
    <Link to={`/profile/${user.id}/info`} className={classNames(cls.ProfileFriendCard, className)}>
      <Avatar
        size="medium"
        className={cls.avatar}
        src={BACKEND_BASE_URL + user.avatar}
      />
      <h3 className={cls.name}>
        {user.first_name} {user.last_name}
        {!user.first_name && !user.last_name && 'Имя не указано'}
      </h3>
    </Link>
  )
})