import React, { memo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import classNames from 'classnames'
import cls from './NewsSlider.module.scss';

import firstMockSliderImage from '@assets/images/first-mock-slider.png';
import secondMockSliderImage from '@assets/images/second-mock-slider.png';
import { Button } from '@newComponents/shared/Button/Button';
import { Link } from 'react-router-dom';
import { useFetchNewsQuery } from '@services/news.service';
import { BACKEND_BASE_URL } from '@constants/constants';
import { getFormatDateWithoutTime } from '@utils/DateUtils';
import { useWindowSize } from '@hooks/useWindowSize';

interface NewsSliderProps {
  className?: string;
}

const VIEW_SLIDES = 2;
const VIEW_SLIDES_PHONES = 1

export const NewsSlider = memo((props: NewsSliderProps) => {
  const { className } = props;
  const { data } = useFetchNewsQuery();
  const {width} = useWindowSize();
  
  if (data) {
    return (
      <div className={classNames(cls.NewsSlider, className)}>
        <Swiper
          className={cls.swiper}
          modules={[Autoplay]} 
          slidesPerView={width > 768 ? VIEW_SLIDES : VIEW_SLIDES_PHONES}
          spaceBetween={30}
          autoplay={{
              delay: 2500,
              disableOnInteraction: false,
          }}
        >
          {data.map(news => (
            <SwiperSlide 
              key={news.id}
              className={cls.swiperSlide}
              style={{
                backgroundImage: `url(${BACKEND_BASE_URL + news.image})`
              }}
            >
              <div className={cls.mainSlideInfo}>
                <h3 className={cls.title}>
                  {news.title}
                </h3>
                <span className={cls.date}>
                  {getFormatDateWithoutTime(news.created_at)}
                </span>
              </div>
              <div className={cls.mainSlideInfo}>
                <p className={cls.description}>
                  Participate in weekly tournaments to win valuable prizes every week
                </p> 
                <Link className={cls.link} to="/news">Read more</Link>
              </div>
            </SwiperSlide>
          ))}
          <SwiperSlide
            className={cls.swiperSlide}
            style={{backgroundImage: `url(${firstMockSliderImage})`}}
          >
              <div className={cls.mainSlideInfo}>
                <h3 className={cls.title}>
                  Every day
                </h3>
                <span className={cls.date}>
                  05.12.24
                </span>
              </div>
              <div className={cls.mainSlideInfo}>
                <p className={cls.description}>
                  Participate in weekly tournaments to win valuable prizes every week
                </p> 
                <Link className={cls.link} to="/news">Read more</Link>
              </div>
          </SwiperSlide>
          <SwiperSlide
            className={cls.swiperSlide}
            style={{backgroundImage: `url(${secondMockSliderImage})`}}
          >
              <div className={cls.mainSlideInfo}>
                <h3 className={cls.title}>
                  Every day
                </h3>
                <span className={cls.date}>
                  05.12.24
                </span>
              </div>
              <div className={cls.mainSlideInfo}>
                <p className={cls.description}>
                  Participate in weekly tournaments to win valuable prizes every week
                </p> 
                <Link className={cls.link} to="/">Read more</Link>
              </div>
              
          </SwiperSlide>
          <SwiperSlide
            className={cls.swiperSlide}
            style={{backgroundImage: `url(${firstMockSliderImage})`}}
          >
              <div className={cls.mainSlideInfo}>
                <h3 className={cls.title}>
                  Every day
                </h3>
                <span className={cls.date}>
                  05.12.24
                </span>
              </div>
              <div className={cls.mainSlideInfo}>
                <p className={cls.description}>
                  Participate in weekly tournaments to win valuable prizes every week
                </p> 
                <Link className={cls.link} to="/news">Read more</Link>
              </div>
          </SwiperSlide>
          <SwiperSlide
            className={cls.swiperSlide}
            style={{backgroundImage: `url(${secondMockSliderImage})`}}
          >
              <div className={cls.mainSlideInfo}>
                <h3 className={cls.title}>
                  Every day
                </h3>
                <span className={cls.date}>
                  05.12.24
                </span>
              </div>
              <div className={cls.mainSlideInfo}>
                <p className={cls.description}>
                  Participate in weekly tournaments to win valuable prizes every week
                </p> 
                <Link className={cls.link} to="/">Read more</Link>
              </div>
              
          </SwiperSlide>
          <SwiperSlide
            className={cls.swiperSlide}
            style={{backgroundImage: `url(${firstMockSliderImage})`}}
          >
              <div className={cls.mainSlideInfo}>
                <h3 className={cls.title}>
                  Every day
                </h3>
                <span className={cls.date}>
                  05.12.24
                </span>
              </div>
              <div className={cls.mainSlideInfo}>
                <p className={cls.description}>
                  Participate in weekly tournaments to win valuable prizes every week
                </p> 
                <Link className={cls.link} to="/news">Read more</Link>
              </div>
          </SwiperSlide>
          <SwiperSlide
            className={cls.swiperSlide}
            style={{backgroundImage: `url(${secondMockSliderImage})`}}
          >
              <div className={cls.mainSlideInfo}>
                <h3 className={cls.title}>
                  Every day
                </h3>
                <span className={cls.date}>
                  05.12.24
                </span>
              </div>
              <div className={cls.mainSlideInfo}>
                <p className={cls.description}>
                  Participate in weekly tournaments to win valuable prizes every week
                </p> 
                <Link className={cls.link} to="/">Read more</Link>
              </div>
              
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }
})
