import { memo, useCallback, useState } from 'react';
import { BACKEND_BASE_URL } from '@constants/constants';
import { Link } from 'react-router-dom';
import { Button } from '../../../../shared/Button/Button';
import classNames from 'classnames';
import cls from './MarketCard.module.scss';
import { MarketAuctionBidModal } from '../../MarketAuctionBidModal';
import { useCancelAuctionMutation, useCancelSellMutation } from '@services/cards.service';
import toast from 'react-hot-toast';

interface MarketCardProps {
  card: {
    circulation_number: number;
    image_card: string;
    price: number;
    id: number
  };
  isAuction: boolean;
  isMyClub: boolean;
  className?: string;
}

export const MarketCard = memo((props: MarketCardProps) => {
  const { card, isAuction, isMyClub, className } = props;
  const [cancelAuction] = useCancelAuctionMutation();
  const [changedCard, setChangedCard] = useState<typeof card | null>(null);

  const handleChangeBidCard = useCallback((newCard: typeof card) => {
    setChangedCard(newCard);
  }, [])

  const handleCloseBidModal = useCallback(() => {
    setChangedCard(null);
  }, [])

  const handleCancelAuction = useCallback(async () => {
    try {
      await cancelAuction(card.id).unwrap()
      toast.success('Auction has been canceled!')
    } catch (error) {
      toast.error('The auction cannot be cancelled')
    }
  }, [card, cancelAuction])

  return (
    <div className={classNames(cls.MarketCard, className)}>
      <Link to={`/market/cards/${card.id}`}>
        <img
          className={cls.image}
          src={BACKEND_BASE_URL + card.image_card}
          alt=""
        />
      </Link>
      <div className={cls.priceBlock}>
        <h3 className={cls.firstPrice}>
          $
          {' '}
          {card.price}
        </h3>
        <span className={cls.secondPrice}>
          0,25 eth
        </span>
      </div>

      {!isMyClub && (isAuction ? (
        <Button
          className={cls.button}
          theme="contained-grey"
          onClick={() => handleChangeBidCard(card)}
        >
          Make a bet
        </Button>
      ) : (
        <Link to={`/market/cards/${card.id}`}>
          <Button
            className={cls.button}
            theme="contained-grey"
          >
            Buy
          </Button>
        </Link>
      ))}

      {isMyClub && isAuction && (
        <Button
          className={cls.button}
          theme="contained-grey"
          onClick={handleCancelAuction}
        >
          Cancel the lot
        </Button>
      )}

      {changedCard && (
        <MarketAuctionBidModal
          card={changedCard}
          onClose={handleCloseBidModal}
        />
      )}
    </div>
  );
});
