import { memo, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Tournament } from '@services/tournaments.service';
import cls from './TournamentCard.module.scss';
import { TournamentCardImageBlock } from '../../TournamentCardImageBlock';
import { TournamentPrizePool } from '../../TournamentPrizePool';
import { Button } from '@newComponents/shared/Button/Button';
import { Link } from 'react-router-dom';

interface TournamentCardProps {
  isInstruction: boolean;
  tournament: Tournament;
  className?: string;
}

export const TournamentCard = memo((props: TournamentCardProps) => {
  const { isInstruction, tournament, className } = props;
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    if (tournament.is_participate && tournament.status_tournament === 'ИДЕТ') {
      const intervalId = setInterval(() => {
        const now = new Date();
        const endDate = new Date(tournament.date_end);
        const timeDifference = endDate.getTime() - now.getTime();
      
        if (timeDifference <= 0) {
          setTimeLeft('Турнир окончен');
          clearInterval(intervalId);
        } else {
          const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
          const seconds = Math.floor((timeDifference / 1000) % 60);
      
          // Используем padStart для добавления нолика
          const formattedMinutes = String(minutes).padStart(2, '0');
          const formattedSeconds = String(seconds).padStart(2, '0');
      
          setTimeLeft(`${formattedMinutes}:${formattedSeconds} until the start`);
        }
      }, 1000);
      
      
      return () => clearInterval(intervalId);
    }
  }, [tournament.is_participate, tournament.date_end, tournament.status_tournament]);

  const isLiveTournament = useMemo(() => (
    tournament.status_tournament === 'ИДЕТ'
  ), [tournament])

  return (
    <div className={classNames(
      cls.TournamentCard, className,
      isLiveTournament && cls.live
    )}>
      <TournamentCardImageBlock
        tournament={tournament}
        isLive={isLiveTournament}
      />
      <div className={cls.mainContent}>
        <div className={cls.blockContent}>
          <div className={cls.textInfo}>
            <h3 className={cls.title}>
              Rules
            </h3>
            <p className={cls.description}>
              Pick your best five using players from any club or league and win Sorare Pro Cards! Create the best team to dominate the international break and take home Star rewards!
            </p>
          </div>
          <ul className={cls.mainContentList}>
            <li className={cls.mainContentItem}>
              <h3 className={cls.mainContentItemName}>
                Покрытие
              </h3>
              <h3 className={cls.mainContentItemValue}>
                {tournament.cover}
              </h3>
            </li>
            <li className={cls.mainContentItem}>
              <h3 className={cls.mainContentItemName}>
                Участники
              </h3>
              <h3 className={cls.mainContentItemValue}>
                {tournament.participants}
              </h3>
            </li>
            <li className={cls.mainContentItem}>
              <h3 className={cls.mainContentItemName}>
                Место
              </h3>
              <h3 className={cls.mainContentItemValue}>
                {tournament.place}
              </h3>
            </li>
          </ul>
        </div>
      </div>
      <div className={cls.prize}>
        <div className={cls.blockContent}>
          <TournamentPrizePool className={cls.prizePool} />
          {/* {!tournament.is_participate ? (
            <Link
              to={isInstruction ?
                '/instruction/change-team' :
                `/tournaments/${tournament.id}/change-team`
              }
            >
              <Button className={cls.button} theme='contained-green'>
                Participate in the tournament ${tournament.price}
              </Button>
            </Link>
          ) : (
            <Link to={`/tournaments/${tournament.id}`}>
              <Button
                className={cls.button} theme='contained-grey'
              >
                Go to the tournament
              </Button>
            </Link>
          )} */}

          {tournament.is_participate && tournament.status_tournament === 'ИДЕТ' ? (
            <Link to={`/tournaments/${tournament.id}`}>
              <Button
                className={classNames(cls.button, cls.buttonTimer)}
                theme='contained-grey'
              >
                {timeLeft}
              </Button>
            </Link>
          ) : (
            <>
              {tournament.is_participate ? (
                <>
                  {tournament.status_tournament === 'ИДЕТ' || tournament.status_tournament === 'НЕ НАЧАЛСЯ' ? (
                    <Link to={`/tournaments/${tournament.id}`}>
                      <Button
                        className={cls.button} theme='contained-grey'
                      >
                        Go to the tournament
                      </Button>
                    </Link>
                  ) : (
                    <Link to={`/tournaments/${tournament.id}/grid`}>
                      <Button
                        className={cls.button} theme='contained-grey'
                      >
                        Go to the tournament results
                      </Button>
                    </Link>
                  )}
                </>
              ) : (
                <>  
                  {tournament.status_tournament === 'НЕ НАЧАЛСЯ' ? (
                    <Link
                    to={isInstruction ?
                      '/instruction/change-team' :
                      `/tournaments/${tournament.id}/change-team`
                    }
                  >
                    <Button
                      className={cls.button}
                      theme='contained-green'
                    >
                      Participate in the tournament ${tournament.price}
                    </Button>
                  </Link>
                ) :  (
                    <Link to={`/tournaments/${tournament.id}/grid`}>
                      <Button
                        className={cls.button} theme='contained-grey'
                      >
                        Go to the tournament grid
                      </Button>
                    </Link>
                )}
                </>
              )}
            </>
          )}
        </div>
      </div>

    </div>
  );
});