import { BACKEND_URL } from '@constants/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from './AuthService';
import { getStandartBaseQuery } from '../config/getStandartBaseQuery';

export interface Friend {
  avatar: null | string;
  first_name: string;
  id: number;
  is_follow: boolean;
  last_name: string;
  nickname: string;
}

export const usersAPI = createApi({
  reducerPath: 'usersAPI',
  baseQuery: getStandartBaseQuery,
  endpoints: (builder) => ({
    fetchUserFriends: builder.query<Friend[], number | string>({
      query: (userId) => ({
        url: `/account/${userId}/followers/`
      })
    }),
    fetchUserSubscriptions: builder.query<Friend[], number | string>({
      query: (userId) => ({
        url: `account/${userId}/followings/`
      })
    }),
    fetchFriendsByName: builder.query<void, string>({
      query: (searchQuery) => ({
        url: 'account/search/',
        params: {
          query: searchQuery
        }
      })
    })
  })
});

export const {
  useFetchUserFriendsQuery,
  useFetchUserSubscriptionsQuery,
  useLazyFetchFriendsByNameQuery
} = usersAPI;