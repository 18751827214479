import { memo } from 'react';
import classNames from 'classnames';
import { Profile } from '@services/profile.service';
import { useSelector } from 'react-redux';
import { Input } from '../../../../shared/Input/Input';
import cls from './EditProfileForm.module.scss';
import { Button } from '../../../../shared/Button/Button';

interface EditProfileFormProps {
  user: Profile;
  className?: string;
}

export const EditProfileForm = memo((props: EditProfileFormProps) => {
  const { user, className } = props;
  const currentUser = useSelector((state) => state?.auth?.user);

  return (
    <form className={classNames(cls.EditProfileForm, className)}>
      <div className={cls.inputs}>
        <div className={cls.twiceBlock}>
          <Input
            className={cls.twiceInputBlock}
            inputClassName={cls.input}
            labelClassName={cls.label}
            defaultValue={currentUser.nickname}
            label="Nickname"
          />
        </div>
        <div className={cls.twiceBlock}>
          <Input
            className={cls.twiceInputBlock}
            inputClassName={cls.input}
            labelClassName={cls.label}
            defaultValue={currentUser.first_name}
            label="First name"
          />
          <Input
            className={cls.twiceInputBlock}
            inputClassName={cls.input}
            labelClassName={cls.label}
            defaultValue={currentUser.last_name}
            label="Last name"
          />
        </div>
        <div className={cls.twiceBlock}>
          <Input
            className={cls.twiceInputBlock}
            inputClassName={cls.input}
            labelClassName={cls.label}
            defaultValue={currentUser.phone || ''}
            label="Phone Number"
          />
          <Input
            className={cls.twiceInputBlock}
            inputClassName={cls.input}
            labelClassName={cls.label}
            defaultValue={currentUser.email || ''}
            label="E-mail"
          />
        </div>
      </div>
      <div className={cls.passwordBlock}>
        <div className={classNames(cls.twiceBlock)}>
          <Input
            className={cls.twiceInputBlock}
            inputClassName={cls.input}
            labelClassName={cls.label}
            label="Old password"
            type="password"
          />
          <Input
            className={cls.twiceInputBlock}
            inputClassName={cls.input}
            labelClassName={cls.label}
            label="New password"
            type="password"
          />
        </div>
        <div className={classNames(cls.twiceBlock)}>
          <Input
            className={cls.twiceInputBlock}
            inputClassName={cls.input}
            labelClassName={cls.label}
            label="Confirm the new password"
            type="password"
          />
          <p className={cls.description}>
            You can change the password only once.
          </p>
        </div>
      </div>
      <Button
        className={cls.button}
        theme="contained-green"
      >
        Save
      </Button>
    </form>
  );
});
