import { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { BackButton } from '@newComponents/shared/BackButton/BackButton';
import { Page } from '../../../shared/Page/Page';
import cls from './Tournaments.module.scss';
import { TournamentsList } from '../../../ui/tournaments/TournamentsList';
import { TournamentAds } from '../../../ui/tournaments/TournamentAds';
import { useFetchTournamentsQuery } from '@services/tournaments.service';
import { useSearchParams } from 'react-router-dom';
import {
  TournamentsFilters
} from '@newComponents/ui/tournaments/TournamentsFilters';

interface TournamentsProps {
  className?: string;
}

export type TournamentsStatusType = 'all' | 'paid' | 'past'

export const Tournaments = memo((props: TournamentsProps) => {
  const { className } = props;
  const [searchParams] = useSearchParams();
  const [tournamentsType, setTournamentsType] = useState<TournamentsStatusType>('all');
  const { data } = useFetchTournamentsQuery({
    price: searchParams.get('price') || 'paid',
    status: tournamentsType
  }, {
    pollingInterval: 10000
  });

  const handleChangeTournamentsType = useCallback((type: TournamentsStatusType) => {
    setTournamentsType(type);
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Page className={classNames(cls.Tournaments, className)}>
      <BackButton className={cls.backButton} />
      <TournamentAds className={cls.ads} />
      <TournamentsFilters
        tournamentsType={tournamentsType}
        onChangeTournamentsType={handleChangeTournamentsType}
      />
      {data && <TournamentsList tournaments={data} />}
    </Page>
  );
});
