import { memo } from 'react';
import { RatingCard } from '@services/rating.service';
import mockCardImage from '@assets/images/mock-cards/1.jpg';
import classNames from 'classnames';
import { BACKEND_BASE_URL } from '@constants/constants';
import cls from './RatingPlayerCard.module.scss';

interface RatingPlayerCard {
  item: RatingCard;
  topLevel?: number;
  className?: string;
}

export const RatingPlayerCard = memo((props: RatingPlayerCard) => {
  const { item, topLevel, className } = props;

  return (
    <div className={classNames(cls.RatingPlayerCard, className)}>
      {topLevel && (
        <div className={classNames(cls.rate, topLevel <= 3 && cls.gold)}>
          #
          {topLevel}
        </div>
      )}
      <img className={cls.card} src={BACKEND_BASE_URL + item.image_card} alt="" />
      <div className={cls.textInfo}>
        <h3 className={cls.name}>
          {item.nickname}
        </h3>
        <div className={cls.statsBlock}>
          <div className={cls.statsCountBlock}>
            <span className={cls.statsCountName}>
              Rank
            </span>
            <span className={cls.statsCount}>
              {item.rarity}
            </span>
          </div>
          <div className={cls.statsCountBlock}>
            <span className={cls.statsCountName}>
              Tournaments
            </span>
            <span className={cls.statsCount}>
              {item.user}
            </span>
          </div>
        </div>
        <div className={cls.priceBlock}>
          <h3 className={cls.price}>
            US$107.38
          </h3>
          <span className={cls.anotherPrice}>
            0/0278 ETH
          </span>
        </div>
      </div>
    </div>
  );
});
